F<template>
    <div>
      <h1>Call Panel for Operators</h1>
<!--   
      <div class="filter-container mb-3">
        <label for="manager-filter">Filter by Manager: </label>
        <select id="manager-filter" class="form-control" v-model="selectedManager" @change="filterByManager">
          <option value="all">All</option>
          <option v-for="manager in managers" :key="manager" :value="manager">{{ manager }}</option>
        </select>
      </div> -->

      <audio id="manager-audio" autoplay></audio>
  
      <!-- Make the table horizontally scrollable on small screens -->
      <div class="table-responsive">
        <table class="table table-bordered" id="call-panel">
          <thead>
            <tr>
              <th>Rep Name</th>
              <th>Start Time</th>
              <th>Notification</th>
              <th>Chat Button</th>
              <!-- <th>Consistent Barge</th> -->
              <th>Single Call Barge</th>
              <th>Mute</th>
              <th>Currently Barging</th>
              <th>2 Min / Total Calls = %</th>
              <th>TO's Requested</th>
              <th>Barges</th>
              <th>TO's</th>
              <th>Current Call Time</th>
              <th>Time Since Last Call</th>
              <th>Owner's Name</th>
              <th>Company Name</th>
              <th>Industry</th>
              <th>Website</th>
              <th>Manager</th>
              <th>State</th>
              <th>Online Marketing Before (Y/N)</th>
              <th>Current Marketing</th>
              <th>Monthly Marketing Budget</th> 
              <th>More Business (Y/N)</th>
              <th>Free Marketing (Y/N)</th>
              <th>Sign Up</th>
              <th>Talk Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="call in filteredCalls" :class="getClass(call)" >
                <td>{{ call.repName }}</td>
                <td>{{  call.startTime }}</td>
                <td style="text-align: center; vertical-align: middle;">
                    <div v-if="call.toRequested == true">TO Requested
                        <i 
                        class="fa-solid fa-exclamation" 
                        v-if="call.toRequested == true" 
                        style="color: red; font-size: 2rem;">
                    </i>
                    </div>
                </td>
              <td>
                <span class="badge bg-danger fs-4" v-if="call.numUnread > 0">{{ call.numUnread }}</span>
                <button class="btn btn-primary chat-button" @click="openChat(call)">Chat</button>
                </td>
              <!-- <td><button class="btn consistent-barge-button" @click="handleBargeClick(call.manager)">Consistent Barge</button></td> -->
              <td>
                <button class="btn btn-success single-barge" @click="handleBargeClick(call)" v-if="!isListening">Single Call Barge</button>
                <button class="btn btn-success single-barge" @click="closeBarge(call)" v-if="isListening && currentSalesRepId === call.id">Close Barge</button>
              </td>
              <td>
                <button  @click="toggleMute" v-if="isListening && currentSalesRepId === call.id">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
                <div v-if="!isListening">-</div>
              </td>
              <td class="currently-barging">{{ call.barging ? `${call.manager} is Barging` : '' }}</td>
              <td>{{ call.twoMinCalls }}</td>
              <td>{{ call.tosRequested }}</td>
              <td>{{ call.barges }}</td>
              <td>{{ call.tos }}</td>
              <td class="length">{{ formatTime(connectedCallTime(call)) }}</td>
              <td class="time-since-last-call">{{ call.lastCallEndTime ? formatTime(timeSinceLastCall(call.lastCallEndTime)) : '-' }}</td>
              <td>{{ call.ownerName }}</td>
              <td>{{ call.companyName }}</td>
              <td>{{ call.industry }}</td>
              <td style="max-width:300px;"><a :href="call.website" target="_blank">{{ call.website }}</a></td>
              <td>{{ call.manager }}</td>
              <td>{{ call.state }}</td>
              <td>{{ call.onlineMarketingBefore }}</td>
              <td>{{ call.currentMarketing }}</td>
              <td>{{ call.monthlyMarketingBudget }}</td>
              <td>{{ call.moreBusiness }}</td>
              <td>{{ call.freeMarketing }}</td>
              <td><button class="btn btn-success" @click="signUpProspect(call)">Sign Up</button></td>
              <td>{{ call.talkTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="manager-chat">
        <!-- Collapsible chat panel -->
        <b-collapse id="managerchat" class="messanger-pop" v-model="chatVisible">
            <b-card group>
            <!-- Badge showing unread messages -->
            <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span>
            <!-- Chat component -->
            <Chat :type="'MANAGER'" 
                    :salesRepId="currentSalesRepId" 
                    :managerId="managerId" 
                    :headerTitle="`Chat with ${currentSalesRepName}`" 
                    @new_message="handleMessageNotification"
                    @close-modal="closeChat" 
                    v-if="chatVisible" />
            </b-card>
        </b-collapse>

        <!-- Icon that toggles the chat panel -->
        <div class="messanger-icon" align-self="center">
            <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span>
            <!-- Toggle icon that shows/hides chat -->
            <a >
            <chatSVG />
            </a>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { TelnyxRTC } from '@telnyx/webrtc';
 import SocketService from './socketService.js';
 import Chat from "../../components/sales/Chat.vue";
 import AuthService from '../../_services/AuthService.js';
 import ChatSVG from '../../components/SVG-icons/chatSVG.vue';
 
 export default {
    components: { 'Chat': Chat, ChatSVG },
    data() {
      return {
        selectedManager: 'all',
        chatVisible: false,
        chatRep: '',
        managers: ['Manager A', 'Manager B', 'Manager C'],
        calls: [
          {
            repName: 'John Doe',
            manager: 'Manager A',
            status: 'ongoing',
            seconds: 120,
            lastCallSeconds: 0,
            barges: '12/1h 22m',
            tos: 13,
            length: '2:00',
            currentCallTime: 0,
            timeSinceLastCall: '0:00',
            ownerName: 'Jane Smith',
            companyName: 'ABC Corp',
            website: 'www.abccorp.com',
            state: 'CA',
            onlineMarketingBefore: 'Yes',
            currentMarketing: 'Word of Mouth',
            monthlyMarketingBudget: '$500',
            moreBusiness: 'Yes',
            freeMarketing: 'No',
            tosRequested: 3,
            talkTime: '4h 32m',
            barging: true,
            isDialing: false,
            currentCallTime: 0,
            toRequested: false
          },
          {
            repName: 'Jane Roe',
            manager: 'Manager B',
            status: 'completed',
            seconds: 180,
            lastCallSeconds: 60,
            barges: '5/45m',
            tos: 13,
            length: '',
            currentCallTime: 0,
            timeSinceLastCall: '1:00',
            ownerName: 'John Smith',
            companyName: 'XYZ Ltd',
            website: 'www.xyzltd.com',
            state: 'NY',
            onlineMarketingBefore: 'No',
            currentMarketing: 'Social Media',
            monthlyMarketingBudget: '$600',
            moreBusiness: 'No',
            freeMarketing: 'Yes',
            tosRequested: 2,
            talkTime: '3h 15m',
            barging: false,
            isDialing: false,
            currentCallTime: 0,
            toRequested: false
          }
        ],

        // timers
        now: new Date(),
        callStartTime: null,

        // chat
        currentSalesRepId: '',
        currentSalesRepName: '',
        managerId: '',
        listenNumber: '',
        repId: '',
        callId: '',
        numUnread: 0,
        chatVisible: false,


        // barge
        telnyx: null,
        currentCall: null,
        isMuted: true,
        username: 'russell32925',
        password: 'gbtKZ5ym',
        callerId: '+19492884866',
        usernameTwo: 'cmharrisiii48614',
        passwordTwo: 'T0Do3WIN',
        callerIdTwo: '+13125300209',

        usernameThree: 'cmharrisiii43213',
        passwordThree: 'nvaXm1Um',
        callerIdThree: '+19494012681',

        usernameFour: 'cmharrisiii46817',
        passwordFour: 'VCrbl4Sk',
        callerIdFour: '+19494012684',
        
        usernameFive: 'cmharrisiii11181',
        passwordFive: '0eQAOTxT',
        callerIdFive: '+19494012740',
        
        usernameSix: 'cmharrisiii78023',
        passwordSix: 'hLPlbajD',
        callerIdSix: '+19494012743',
        
        usernameSeven: 'cmharrisiii36261',
        passwordSeven: 'kXOTAE7Z',
        callerIdSeven: '+19494012744',
        
        telnyxRTC: null,
        localAudioTrack: null,
        isListening: false,
        
      };
    },
    computed: {
        filteredCalls() {
            // Filter calls based on selected manager
            //console.log('filter');
            let filtered = this.selectedManager === 'all'
                ? this.calls
                : this.calls.filter(call => call.manager === this.selectedManager);

            // Sort the filtered calls
            let result = filtered.sort((a, b) => {
                // First, sort by currentCallTime (longest to shortest)
                if (a.currentCallTime > 0 && b.currentCallTime > 0) {
                    return b.currentCallTime - a.currentCallTime; // Longer currentCallTime comes first
                }

                // If one has currentCallTime > 0 and the other doesn't, prioritize the one with currentCallTime > 0
                if (a.currentCallTime > 0) return -1; // a comes first
                if (b.currentCallTime > 0) return 1;  // b comes first

                // Both currentCallTime are 0, so sort by lastCallEndTime (most recent to least recent)
                return new Date(b.lastCallEndTime) - new Date(a.lastCallEndTime);
            });

           // console.log('filtered: ', result);
            return result;
        }
    },
    methods: {
        async signUpProspect(rep) {
            let signupLink = 'https://www.180ads.com/reppricing';
            signupLink += `?rep_id=${rep.id}&lead_id=${rep.leadId}`;
            window.open(signupLink, '_blank');
        },
        async closeBarge(_call) {
            if(this.currentCall) {
                await this.currentCall.hangup();
            }
            const call = this.calls.find(call => call.id === _call.id);
            if (call) {
                call.barging = false;
            }
            let data = {
                event: 'update-barge',
                salesRepId: _call.id,
                managerId: _call.managerId,
                callId: _call.callId,
                status: 'close'
            };
            SocketService.emit('update-barge', data);
            this.currentSalesRepId = '';
            this.isListening = false;
        },
        closeChat() {
            this.chatVisible = false;
        },
        openChat(rep) {
            this.currentSalesRepId = rep.id;
            this.currentSalesRepName = rep.repName;
            this.managerId = rep.managerId;
            rep.numUnread = 0;
            this.chatVisible = !this.chatVisible;
            console.log("repName: ", this.currentSalesRepName);
            console.log("repOpenChat: ", rep);
        },
        handleMessageNotification(event) {
            console.log('event: ', event);
            // Select the element
            const chatElement = document.getElementById('chat-collapse');
            if(!chatElement.classList.contains('show')) {
                const linkElement = document.getElementById('chat-link');
                linkElement.click();
            }
        },
        timeSinceLastCall(endLastCall) {
            const end = endLastCall || new Date();
            const timeDifference = this.now - new Date(end); // Difference in milliseconds
            let timeDiff = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
            return timeDiff;
        },
        connectedCallTime(rep) {
            if(rep.callStartTime) {
                const timeDifference = this.now - rep.callStartTime; // Corrected the subtraction
                let timeDiff = Math.floor(timeDifference / 1000); // Time difference in seconds
                return timeDiff;
            } else {
                return 0;
            }
        },
        formatTime(seconds) {
            if (seconds <= 0) {
                return '-';
            }

            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            let formattedTime = '';

            if (hours > 0) {
                formattedTime += `${hours}h `;
            }
            if (minutes > 0 || hours > 0) {
                formattedTime += `${minutes}m `;
            }
            formattedTime += `${secs}sec`;

            return formattedTime.trim();
            },
        mapInputToCalls(input) {
            return input.map(rep => {
                const today = new Date().toLocaleDateString('en-US', {
                    weekday: 'short', 
                    year: 'numeric', 
                    month: 'short', 
                    day: '2-digit'
                }); 

                console.log('today: ', today);
               
                // Get the most recent data entry for the current day, if exists
                const todayData = rep.data[today] || { barges: 0, loggedTime: 0, talkTime: 0, completedTOs: 0, requestedTOs: 0 };

                return {
                    id: rep.id,
                    repName: rep.name,
                    startTime: this.getStartTime(todayData),
                    manager: rep.manager,
                    managerId: rep.managerId,
                    status: 'temp', // rep.isLoggedIn ? 'ongoing' : 'completed',
                    seconds: rep.currentCallTime || 0, // assuming current call time is in seconds
                    lastCallSeconds: this.getLastCallSeconds(rep.lastCallEndTime), // Calculate time since last call
                    barges: `${todayData.barges}/${this.formatSeconds(todayData.talkTime)}`, // Barges / Total logged in time in hours and minutes for today
                    tos: parseInt(todayData.completedTOs, 10) || 0, // Completed TOs for today
                    length: this.formatSeconds(rep.currentCallTime), // Length of the current call
                    currentCallLength: 0,
                    timeSinceLastCall: this.formatSeconds(rep.timeSinceLastCall), // Time since last call
                    ownerName: '', // Placeholder as it's not in the input
                    companyName: '', // Placeholder as it's not in the input
                    website: '', // Placeholder as it's not in the input
                    state: '', // Placeholder as it's not in the input
                    onlineMarketingBefore: '', // Placeholder as it's not in the input
                    currentMarketing: '', // Placeholder as it's not in the input
                    monthlyMarketingBudget: '', // Placeholder as it's not in the input
                    moreBusiness: '', // Placeholder as it's not in the input
                    freeMarketing: '', // Placeholder as it's not in the input
                    tosRequested: parseInt(todayData.requestedTOs, 10) || 0, // TOs Requested for today
                    talkTime: this.formatSeconds(todayData.talkTime), // Total talk time for today
                    barging: rep.isListening, // Assuming isListening refers to barging status
                    isDialing: rep.isDialing,
                    currentCallTime: rep.currentCallTime || 0,
                    lastCallEndTime: rep.lastCallEndTime,
                    numUnread: rep.numUnread,
                    toRequested: rep.toRequested || false,
                    twoMinCalls: this.getCalls(todayData)
                };
            });
            },

            getCalls(data) {
                let callsCount = (data?.humanCalls || '-') + '/' + (data?.calls || '-');
                let humanPercent = 0;
                if(data?.humanCalls > 0) {
                    humanPercent = parseFloat((data?.humanCalls / data?.calls) * 100).toFixed(1);
                    callsCount += '\n' + (humanPercent) + '%';
                }
                return callsCount;
            },
            getStartTime(data) {
                let result = data.startTime;
                if(!result) {
                    return '-';
                }
                // Initialize the date in UTC
                const date = new Date(`${result}Z`); // Ensure it's treated as UTC
                if (isNaN(date)) {
                    return '-';
                }

                const options = {
                    timeZone: 'America/Los_Angeles',  // Convert to Pacific Time
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };

                return new Intl.DateTimeFormat('en-US', options).format(date);
            },

            // Helper function to format seconds into hours and minutes
            formatSeconds(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            return `${hours}h ${minutes}m`;
            },

            // Helper function to calculate time since the last call
            getLastCallSeconds(lastCallEndTime) {
            const now = new Date();
            const lastCall = new Date(lastCallEndTime);
            const diffInSeconds = Math.floor((now - lastCall) / 1000);
            return diffInSeconds;
            },

            // Helper function to get the most recent day's data
            getLatestData(data) {
            const sortedKeys = Object.keys(data).sort((a, b) => new Date(b) - new Date(a));
            return data[sortedKeys[0]];
            },
      closeModal() {
        this.chatVisible = false;
      },
      filterByManager() {
        // Manager filtering logic is handled via computed property
      },
    //   openChat(rep) {
    //     this.chatRep = rep;
    //     this.chatVisible = true;
    //   },
      handleBargeClick(manager) {
        // Update barge indicator for clicked manager

        this.currentSalesRepId = manager.id;
        let _manager = manager.manager;
        const call = this.calls.find(call => call.manager === _manager);
        if (call) {
          call.barging = true;
        }
        call.toRequested = false;
        this.bargeCall(manager);
      },
      getClass(rep) {
        return {
            'connected': rep.currentCallTime > 0 && !rep.isDialing,
            'two-min': rep.currentCallTime > 120 && !rep.isDialing,
            'five-min': rep.currentCallTime > 300 && !rep.isDialing,
            'dialing': rep.isDialing
        };
    },
      async setManagerId() {
        let role = await AuthService.getUserRole();
        this.role = role;
        if(role == 'Admins' && this.$route.path == '/panel') {
            //target += '?id=' + this.$route.query.id;
            this.managerId = '123';
        }
        
        if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
            this.managerId = this.$route.query.id;
        }
        if(role == 'Managers') {
            this.managerId = await AuthService.getCognitoSub();
        }
        console.log('managerId: ', this.managerId);
        },
        configureSockets() {
            SocketService.connect();
            SocketService.register(this.managerId);
            SocketService.on('new_message', this.handleNewMessage);
            SocketService.on('request-to', this.notifyTORequest);
            SocketService.on('call-update', this.callUpdate);
            SocketService.on('barge-update', this.bargeUpdate);
            SocketService.on('analysis-update', this.analysisUpdate);
            SocketService.on('user-status', this.userStatus);
            SocketService.on('call-status', this.callStatus);
            SocketService.on('heartbeat', this.handleHeartbeat);
            SocketService.on('script-update', this.scriptUpdate);
            SocketService.on('update-barge', this.updateBarge);
        },
        updateBarge(data) {
            this.calls.forEach((rep) => {
                if(rep.id === data.salesRepId) {
                    if(data.status === 'open') {
                        rep.isListening = true;
                        rep.barging = true;
                    }
                    if(data.status === 'close') {
                        rep.isListening = false;
                        rep.barging = false;
                    }
                }
            });
        },
        scriptUpdate(data) {
            console.log('data: ', JSON.stringify(data));
            this.updateCalls(data); 
        },
        updateCalls(input) {
            console.log("input: ", input);
            // Iterate through the existing calls and update the fields with input data
            this.calls.forEach((call) => {
            // Check if the current call matches the salesRepId or managerId
            //console.log('call.id: ', call.id);
            if (call.id === input.salesRepId || call.manager === input.managerId) {
                // Update call details based on input data
                //call.repName = input.data.name || call.repName;

                if(input.data.isNew) {
                    call.toRequested = false;
                    call.campaignId = null;
                    call.leadId = null;
                    call.callId = null;
                    call.ownerName = null;
                    call.status = null;
                    call.companyName = '';
                    call.industry = null;
                    call.website = null;
                    call.onlineMarketingBefore = null;
                    call.monthlyMarketingBudget = null;
                    call.moreBusiness = null;
                    call.freeMarketing = null;
                    call.state = null;
                    call.currentCallTime = 0;
                    call.isDialing = false;
                    call.isActive = false;
                    call.lastCallEndTime = new Date();
                    call.callStartTime = null;
                    this.$forceUpdate();
                } else {
                    call.campaignId = input.data.campaignId;
                    call.leadId = input.data.leadId;
                    call.callId = input.data.callId;
                    call.ownerName = input.data.name;
                    call.status = input.data.confirmation === "yes" ? "ongoing" : "completed";
                    call.companyName = input.data.businessName || call.companyName;
                    call.industry = input.data.industry || '-';
                    call.website = input.data.hasWebsite ? input.data.website : '';
                    call.onlineMarketingBefore = input.data.hasDoneOnlineMarketing === "yes" ? "Yes" : "No";
                    call.currentMarketing = input.data.clientsFrom.join(", ") || call.currentMarketing;
                    call.monthlyMarketingBudget = input.data.advertisingBudget.join(", ") || call.monthlyMarketingBudget;
                    call.moreBusiness = input.data.wantMoreBusiness === "yes" ? "Yes" : "No";
                    call.freeMarketing = input.data.boostOnlinePresence === "yes" ? "Yes" : "No";
                    call.state = input.data.state;
                }

                // You can add more fields here as required
            }
            });
        },
        handleNewMessage(message) {
            console.log('newMessage: ', message);
            if(message.sender == message.sales_rep_id) {
                this.calls.forEach((rep) => {
                if(rep.id == message.sales_rep_id) {
                    if(this.currentSalesRepId == message.sales_rep_id && this.chatVisible == true) {

                    } else {
                        rep.numUnread = rep.numUnread + 1;
                    }
                    
                }
                });
            }
        },
        notifyTORequest(data) {
            try {

                this.calls.forEach((rep) => {
                    if(rep.id == data.salesRepId) {
                        //console.log("rep: ", rep);
                        rep.toRequested = true;
                        this.callId = data.callId;
                        try {
                            // Find the latest day for the rep
                            const latestDay = Object.keys(rep.data).sort().pop();
                            const latestDayData = rep.data[latestDay];

                            // If latest day data is found, update the requestedTOs
                            if (latestDayData) {
                                // Update requestedTOs
                                latestDayData.requestedTOs = (latestDayData.requestedTOs || 0) + 1;
                                console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
                            } else {
                                console.error(`No data found for the latest day for rep ${rep.id}`);
                            }
                        } catch (err) {
                            console.error('error on notifyTO: ', err);
                        }
                    }
                }); 
            } catch (err) { 
                console.error('error notifying manager of TO request: ', err);
            }
            },
            callUpdate(data) {
            // Iterate through the salesReps list to find the matching salesRepId
            this.calls.forEach((rep) => {
                if (rep.id === data.salesRepId) {
//                console.log("Updating rep: ", rep);

                // Find the latest day for the rep
                const latestDay = Object.keys(rep.data).sort().pop();
                const latestDayData = rep.data[latestDay];

                // If latest day data is found, update the talkTime and humanCalls
                if (latestDayData) {
                    // Update talkTime
                    latestDayData.talkTime = (latestDayData.talkTime || 0) + data.talkTime;
                    // Update humanCalls (mapped from twoMinCalls)
                    latestDayData.humanCalls = (latestDayData.humanCalls || 0) + data.twoMinCalls;

                    console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
                } else {
                    console.error(`No data found for the latest day for rep ${rep.id}`);
                }
                }
            });
            },
            bargeUpdate(data) {
            // Iterate through the salesReps list to find the matching salesRepId
            console.log('bargeUpdate: ', data);
            this.calls.forEach((rep) => {
                if (rep.id === data.salesRepId) {
                // console.log("Updating barge count for rep: ", rep);

                // // Find the latest day for the rep
                // const latestDay = Object.keys(rep.data).sort().pop();
                // const latestDayData = rep.data[latestDay];

                // // If latest day data is found, increment the barges count
                // if (latestDayData) {
                //   latestDayData.barges = (latestDayData.barges || 0) + 1;
                //   console.log(`Barge count updated for ${rep.name} on ${latestDay}. New barges count: ${latestDayData.barges}`);
                // } else {
                //   console.error(`No data found for the latest day for rep: ${rep.id}`);
                // }
                }
            });
            },
            analysisUpdate(data) {
            // Iterate through the salesReps list to find the matching salesRepId
            this.calls.forEach((rep) => {
                if (rep.id === data.salesRepId) {
                console.log("Updating completedTOs for rep: ", rep);

                // Find the latest day for the rep
                const latestDay = Object.keys(rep.data).sort().pop();
                const latestDayData = rep.data[latestDay];

                // If latest day data is found, increment the completedTOs count
                if (latestDayData) {
                    latestDayData.completedTOs = (latestDayData.completedTOs || 0) + 1;
                    console.log(`Completed TOs updated for ${rep.name} on ${latestDay}. New completedTOs count: ${latestDayData.completedTOs}`);
                } else {
                    console.error(`No data found for the latest day for rep: ${rep.id}`);
                }
                }
            });
            },
            userStatus(userStatusData) {
            const { salesRepId, status, duration, connectedDuration } = userStatusData;
            // Find the sales rep in the list
            const rep = this.calls.find(rep => rep.id === salesRepId);
            if (!rep) {
                console.error(`Sales rep with id ${salesRepId} not found.`);
                return;
            }

            switch (status) {
                case 'ACTIVE':
                console.log(`Sales rep ${salesRepId} is now active.`);
                rep.isLoggedIn = true;
                rep.isPaused = false;
                rep.isActive = true;
                rep.lastLoggedInTime = rep.loggedInTime;
                //rep.loggedInTime = duration || 0;  // Reset or set logged in time
                //rep.talkTime = connectedDuration || 0;  // Set initial talk time if provided
                break;

                case 'OFFLINE':
                console.log(`Sales rep ${salesRepId} is now offline.`);
                rep.isLoggedIn = false;
                rep.isPaused = false;
                rep.isActive = false;
                rep.lastLoggedInTime = rep.loggedInTime;
                //rep.loggedInTime = duration || rep.loggedInTime;  // Finalize logged time
                rep.talkTime = connectedDuration || rep.talkTime;  // Finalize talk time
                // You can stop any timers here, if applicable
                break;

                case 'PAUSED':
                console.log(`Sales rep ${salesRepId} has paused.`);
                rep.isPaused = true;
                rep.isActive = false;
                //rep.loggedInTime = duration || rep.loggedInTime;  // Update logged time
                rep.talkTime = connectedDuration || rep.talkTime;  // Update talk time
                // Pause any ongoing call tracking or timers if applicable
                break;

                default:
                console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
            }
        },
        callStatus(callStatusData) {
            const { salesRepId, status, callEndTime } = callStatusData;
            
            console.log('this.calls: ', this.calls);
            const rep = this.calls.find(rep => rep.id === salesRepId);
            if (!rep) {
                console.error(`Sales rep with id ${salesRepId} not found.`);
                return;
            }


            console.log('callStatus: ', rep);
            switch (status) {
                case 'DIALING':
                console.log(`Sales rep ${salesRepId} is dialing.`);
                rep.isDialing = true;
                rep.isActive = false;
                rep.isPaused = false;
                rep.callStartTime = null;
                break;

                case 'ACTIVE':
                console.log(`Sales rep ${salesRepId} is on an active call.`);
                rep.isDialing = false;
                rep.isActive = true;
                rep.isPaused = false;
                rep.lastCallEndTime = null;
                rep.callStartTime = new Date();
                break;

                case 'PAUSED':
                console.log(`Sales rep ${salesRepId} has paused the call.`);
                rep.isDialing = false;
                rep.isActive = false;
                rep.isPaused = true;
                
                break;

                case 'INACTIVE':
                console.log(`Sales rep ${salesRepId} has ended the call.`);
                rep.isDialing = false;
                rep.isActive = false;
                rep.isPaused = false;
                rep.callStartTime = null;
                this.handleCallEndEvent(callStatusData);  // Call handleCallEnd to process the call end
                break;

                default:
                console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
            }
            console.log('callStatus: ', rep);
            console.log('callStatus: ', this.calls);
            this.$forceUpdate();
        },
        async fetchSalesReps(target) {
            let role = await AuthService.getUserRole();
            console.log('role: ', role);
            console.log('path: ', this.$route.path);
            if(role == 'Admins' && this.$route.path == '/scoreboard') {
                //target += '?id=' + this.$route.query.id;
            }
            
            if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
            target += '?id=' + this.$route.query.id;
            }
            if(role == 'Managers') {
                target += '?id=' + await AuthService.getCognitoSub();
            }
            axios.get(target)
                .then(response => {
                this.calls = this.mapInputToCalls(response.data);
                //this.calls = response.data;// .filter(rep => rep.id == 'b9a9a9fe-f051-70f4-1ea8-6f786eb62621');
                //this.mapInputToCalls(response.data);
                // this.calls.forEach((rep) => {
                //   //this.startLocalTimerforRep(rep);
                //   this.startLocalTimerForRep(rep);
                //   //this.startTimeSinceLastCallTimer(rep, rep.timeSinceLastCall);
                // });
                console.log('salesReps: ', this.calls);
                })
                .catch(error => {
                console.error('Error fetching sales reps data:', error);
                });
        },
        handleCallEndEvent(callEndData) {
            const rep = this.calls.find(rep => rep.id === callEndData.salesRepId);
            if (rep) {
                rep.currentCallTime = 0;
                rep.lastCallEndTime = new Date();
                rep.callStartTime = null;
                rep.toRequested = false;
               // this.startLocalTimerForRep(rep);
                //this.startTimeSinceLastCallTimer(rep, lastCallEndTime);
            }
        },
        bargeCall(call) {
            this.connect();
            axios.get(`https://123avatars.com/v2/barge-call?id=${call.id}&managerId=${call.managerId}&callId=${call.callId}`)
                .then((resp) => {
                if(resp.data.message) {
                    alert(resp.data.message);
                } else {
                    this.makeCall(this.callerIdTwo, this.callerIdTwo, call);
                }
                }).catch((err) => {
                console.error("error barging call: ", err);
                });
        },
        connect() {
            let id = this.$route.query.id;
            let managerNum = '';
            let user = '';
            let pass = '';
            let callerIdNum = '';
            managerNum = '+13125300209';
                user = this.usernameTwo;
                pass = this.passwordTwo;
                callerIdNum = this.callerIdTwo;
            try {
                this.telnyxRTC = new TelnyxRTC({
                login: user,
                password: pass,
                callerIdNumber: callerIdNum,
                debug: this.enableDebugging,
                logLeveL: 'debug',
                });
            } catch (err) {
                console.error("error init listening", err)
            }
            this.telnyxRTC.on('telnyx.socket.open', () => {
                console.log('Socket opened');
            });

            this.telnyxRTC.on('telnyx.socket.error', (error) => {
                console.error('Socket error', error);
            });

            this.telnyxRTC.on('telnyx.socket.close', () => {
                console.log('Socket closed');
            });

            this.telnyxRTC.on('telnyx.ready', () => {
                console.log('Telnyx ready');
            });

            this.telnyxRTC.on('telnyx.error', (error) => {
                console.error('Telnyx error', error);
            });

            this.telnyxRTC.on('telnyx.call.incoming', (call) => {
                // this.call = call;
                console.log('Incoming call', call);
            });

            this.telnyxRTC.on('telnyx.call.answered', (call) => {
                console.log('Call answered', call);
                //this.attachStreamToAudioElement(this.call.remoteStream);
            });

            this.telnyxRTC.on('telnyx.call.hangup', (call) => {
                console.log('Call hung up', call);
            });

            this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
                console.log('call: ', call);
            });

            this.telnyxRTC.on('telnyx.notification', async (notification) => {
                console.log('telnyxNotification: ', notification);
                try {
                const call = notification.call;

                console.log('call: ', call.tt);

                if (notification.type === 'callUpdate' && call.state === 'ringing') {
                    if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
                    call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
                    ) {
                    // call.answer();
                    // if(this.call) {
                    //   this.mergeCalls(this.call, call);
                    // }
                    }
                }
                if (notification.type === 'callUpdate') {
                if (notification.call.state === 'active') {
                    try {
                    this.listeningCall = notification.call;
                    this.localAudioTrack = this.listeningCall.localStream.getAudioTracks()[0];
                    this.localAudioTrack.enabled = false;
                    this.attachStreamToAudioElement(notification.call.remoteStream);
                    } catch (err) {
                    console.error('error attaching stream to audio element: ', err);
                    }
                }
                }
                } catch (err) {
                console.log("Error answering call: ", err);
                }
            });

            this.telnyxRTC.connect();
            },
            toggleMute() {
            console.log('toggleMute: ', this.isMuted);
            this.isMuted = !this.isMuted;
            if(this.localAudioTrack) {
                this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
            }
            console.log('isMuted: ', this.isMuted);
            },
            makeCall(fromNum, toNum, call) {
                console.log("fromNum: ", fromNum);
                console.log("toNum: ", toNum);
                if (this.telnyxRTC) {
                    if (toNum.length === 10) {
                    toNum = '+1' + toNum;
                    }
                    fromNum = '+13125300209';
                    this.currentCall = this.telnyxRTC.newCall({
                    destinationNumber: fromNum,
                    callerNumber: fromNum,
                    });
                    this.isListening = true;
                }
                let data = {
                    event: 'update-barge',
                    salesRepId: call.id,
                    managerId: call.managerId,
                    callId: call.callId,
                    status: 'open'
                };
                SocketService.emit('update-barge', data);
            },
            attachStreamToAudioElement(stream) {
            const audioElement = document.getElementById('manager-audio');
            audioElement.srcObject = stream;
            console.log('audioElementAttached: ', audioElement);
            },
            connectAudio() {
            if (this.currentCall) {
                this.currentCall.options.audio = true;
                this.muteCall();
            }
            },
            muteCall() {
            if (this.currentCall) {
                this.currentCall.muteAudio();
                this.isMuted = true;
            }
            },
            unmuteCall() {
            if (this.currentCall) {
                this.currentCall.unmuteAudio();
                this.isMuted = false;
            }
        },
        handleHeartbeat(heartbeatData) {
            //console.log('heartbeatData: ', heartbeatData);
            const rep = this.calls.find(rep => rep.id === heartbeatData.salesRepId);
            if (rep) {
                this.updateRepFromHeartbeat(rep, heartbeatData);
            }
        },
        updateRepFromHeartbeat(rep, heartbeatData) {
        // Update the rep's data from the latest heartbeat
        if(rep.isLoggedIn) {
            if(heartbeatData.loggedInTime != 0) {
            let currDiff = rep.loggedInTime - rep.lastLoggedInTime;
            if(currDiff != heartbeatData.loggedInTime) {
                rep.loggedInTime = rep.lastLoggedInTime + heartbeatData.loggedInTime;
            }
            }
        }
        
        //rep.talkTime = heartbeatData.talkTime;
        //rep.currentCallTime = heartbeatData.currentCallTime;

        // console.log('rep: ', rep);

        // Start local incrementing until the next heartbeat arrives
        this.startLocalTimerForRep(rep);
        },
        startLocalTimerForRep(rep) {
        //      console.log('startTimer: ', rep); 
            if (rep.localTimer) {
                clearInterval(rep.localTimer); // Clear existing timer if running
                rep.localTimer = null;
            }

            // Start a new local timer to increment time every second
            rep.localTimer = setInterval(() => {
                //if() // call status if user ACTIVE increment
            //  console.log('tick');
                if(rep.isLoggedIn) {
                rep.loggedInTime += 1;
                }
                if(rep.isActive == true) {
                rep.currentCallTime += 1;
                rep.timeSinceLastCall = 0;
                } else {
                if(rep.timeSinceLastCall != -1) {
                    rep.timeSinceLastCall += 1;
                }
                }
            //   console.log(`Local time incremented for rep ${rep.id}`);
            }, 1000);
        },
    },
    async mounted() {
        await this.setManagerId();
        this.configureSockets();
        this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
        this.interval = setInterval(() => {
            this.now = new Date();
        }, 1000);

    //   setInterval(() => {
    //     this.calls.forEach(call => {
    //       if (call.status === 'ongoing') {
    //         call.seconds += 1;
    //         const minutes = Math.floor(call.seconds / 60);
    //         const seconds = call.seconds % 60;
    //         call.length = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    //       } else if (call.status === 'completed') {
    //         call.lastCallSeconds += 1;
    //         const minutes = Math.floor(call.lastCallSeconds / 60);
    //         const seconds = call.lastCallSeconds % 60;
    //         call.timeSinceLastCall = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    //       }
    //     });
    //   }, 1000);
    }
  };
  </script>
  
  <style scoped>
  .consistent-barge-button {
    background-color: #ff9800;
  }
  
  .consistent-barge-button:hover {
    background-color: #e68900;
  }
  
  .single-barge {
    background-color: #28a745;
  }
  
  .single-barge:hover {
    background-color: #218838;
  }
  
  .connected {
        background-color: yellow;
    }
    .two-min {
        background-color: lightgreen;
    }
    .five-min {
        background-color: lightsalmon;
    }
    .dialing {
        background-color: lightblue;
    }  

  .chat-box {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 250px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  </style>
  