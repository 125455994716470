<template>
  <div>
    <b-modal v-if="true" v-model="callbackModal" id="callbackModal" title="Setup Callback" size="lg">

      <template #modal-header="{ close }" class="header">
        <div>Call Back for <span class="bold">{{ prospect.company_name }}</span></div>
        <button class="btn" @click="close()">
          <closeSVG />
        </button>
      </template>

      <div class="col-a">
        <!--
        <p><strong>Owner's Name</strong> {{ prospect.owner }}</p>
        <p><strong>Company Name</strong> {{ prospect.company_name }}</p>
        <p><strong>Timezone</strong> {{ prospect.timezone }}</p>
        <p><strong>Local Time</strong> {{ getLocalTimeFromAbbreviation(prospect.timezone) }}</p>
        <p><strong>Your Time:</strong> {{ userTime }}</p>
        <p><strong>Time Difference:</strong> {{ timeDifference }} hours</p>  
         -->
        <b-table :items="callbackmodal_a_items" :fields="callbackmodal_fields" thead-class="hidden_header" class="nopad-first nopad-last">
        </b-table>
      </div>

      <div class="col-b">
        <div class="form-group">
          <input type="datetime-local" v-model="callbackTime" id="callbackTime" class="form-control" @change="calculateTimeDifference">
        </div>
        <b-table :items="callbackmodal_b_items" :fields="callbackmodal_fields" thead-class="hidden_header" class="nopad-first nopad-last">
        </b-table>
      </div>

      <template #modal-footer>
        <div class="buttons">
          <b-button class="btn solid icon" @click="checkAndSetCallback"><plusSVG />Schedule</b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
  import 'moment-timezone/data/packed/latest.json';
  import { DateTime } from 'luxon';
  import CloseSVG from '../../components/SVG-icons/closeSVG.vue';
  import PlusSVG from '../../components/SVG-icons/plusSVG.vue';
  
  export default {
    
    components: { CloseSVG, PlusSVG },
    
    props: {
      prospect: {
        type: Object,
        // required: true,
        default: () => ({
          owner: '',
          company_name: '',
          timezone: '',
          localTime: '',
          leadId: '',
          
        })
      }
    },
    data() {
      return {
        
        callbackmodal_fields: [
          {
            key: 'label',
            variant: 'bold',
          },
          {
            key: 'data',
            variant: 'text-right'
          },
          
        ],
        
        callbackmodal_a_items: [
          {
            label: 'Owner’s Name',
            data: moment('2019-07-16').format('MM/DD/YYYY'),
          },
          {
            label: 'Company Name',
            data: 'ABC Plumbing',
          },
          {
            label: 'Timezone',
            data: 'PST',
          },
          {
            label: 'Local Time',
            data: '10:00 am',
          },
        ],
        callbackmodal_b_items: [
          {
            label: 'Your Time',
            data: '(current time)',
          },
          {
            label: 'Time Difference',
            data: '+2 Hours',
          }
        ],
        
        showModal: false,
        callbackTime: '',
        userTime: '',
        timeDifference: '',
        days: {
          0: "Sunday",
          1: "Monday",
          2: "Tuesday",
          3: "Wednesday",
          4: "Thursday",
          5: "Friday",
          6: "Saturday"
        }
      };
    },
    methods: {
      openModal() {
 //       this.showModal = true;
      },
      closeModal() {
        this.$emit('callbacks-closed'); 
   //     this.showModal = false;
      },
      getLocalTimeFromAbbreviation(abbreviation) {
        // Map of timezone abbreviations to IANA timezone names
        const timezones = {
          'EST': 'America/New_York',        // Eastern Standard Time
          'EDT': 'America/New_York',        // Eastern Daylight Time (same as EST for IANA purposes)
          'CST': 'America/Chicago',         // Central Standard Time
          'CDT': 'America/Chicago',         // Central Daylight Time (same as CST for IANA purposes)
          'MST': 'America/Denver',          // Mountain Standard Time
          'MDT': 'America/Denver',          // Mountain Daylight Time (same as MST for IANA purposes)
          'PST': 'America/Los_Angeles',     // Pacific Standard Time
          'PDT': 'America/Los_Angeles',     // Pacific Daylight Time (same as PST for IANA purposes)
          'AKST': 'America/Anchorage',      // Alaska Standard Time
          'AKDT': 'America/Anchorage',      // Alaska Daylight Time (same as AKST for IANA purposes)
          'HST': 'Pacific/Honolulu',        // Hawaii Standard Time (Hawaii does not observe Daylight Saving Time)
          'HAST': 'Pacific/Honolulu',       // Hawaii-Aleutian Standard Time (same as HST)
          'HADT': 'America/Adak',           // Hawaii-Aleutian Daylight Time
          'AST': 'America/Puerto_Rico',     // Atlantic Standard Time (used in Puerto Rico and the US Virgin Islands)
          'ADT': 'America/Halifax',         // Atlantic Daylight Time (not widely used in the U.S.)
          'CHST': 'Pacific/Guam',           // Chamorro Standard Time (Guam)
          'SST': 'Pacific/Pago_Pago',       // Samoa Standard Time (American Samoa)
        };

        // Get the current date and time
        const now = new Date();

        // Get the IANA timezone name from the abbreviation
        const timeZone = timezones[abbreviation];

        // If the timezone abbreviation is not found, return null
        if (!timeZone) {
          console.error('Timezone abbreviation not found');
          return null;
        }

        // Format the date and time according to the timezone
        const options = {
          timeZone,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat([], options);
        return formatter.format(now);
      },
      calculateTimeDifference() {
        console.log('callbackTime: ', this.callbackTime);


        let timezone = this.prospect.timezone;
        // Parse the callbackTime (assumed to be in the prospect's timezone)
        const prospectTime = DateTime.fromISO(this.callbackTime, { zone: timezone });
        
        // Convert the prospect's time to the browser's local time
        const localTime = prospectTime.setZone(this.getTimezone());

        // Store the local time for display
        this.userTime = localTime.toLocaleString(DateTime.DATETIME_FULL);

        // Calculate the time difference between the prospect's timezone and the local timezone
        const prospectOffset = prospectTime.offset;
        const localOffset = localTime.offset;
        const timeDifference = Math.abs(prospectOffset - localOffset) / 60; // Convert minutes to hours
        this.timeDifference = timeDifference;

        console.log('Converted Local Time: ', this.userTime);
        console.log('Time Difference (in hours): ', this.timeDifference);
    },
      getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    getTimezone() {
      // Get the local timezone
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log('timezone: ', localTimezone);

      // List of US timezones
      const usTimezones = [
        'America/New_York',    // EST/EDT
        'America/Chicago',     // CST/CDT
        'America/Denver',      // MST/MDT
        'America/Phoenix',     // MST (no DST)
        'America/Los_Angeles', // PST/PDT
        'America/Anchorage',   // AKST/AKDT
        'America/Adak',        // HST/HDT
        'Pacific/Honolulu',    // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
        return localTimezone;
      }

      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    async checkAndSetCallback() {
      try {
          // Get the day of the week for the callback time
          const callbackTime = new Date(this.callbackTime);
          const options = { weekday: 'long' };
          const callbackDayName = callbackTime.toLocaleDateString('en-US', options);

          // Make the request with the callback day name
          const response = await axios.get('https://123avatars.com/v2/schedule', {
              params: {
                  userId: '123009',
                  type: 'Callbacks',
                  day: callbackDayName // Use the actual day of the callback here
              }
          });

          const schedule = response.data;
          const userTimezone = this.prospect.timezone;

          // Create date objects for open and close times based on the callback date
          const openTime = new Date(callbackTime);
          const [openHour, openMinute] = schedule.openTime.split(':');
          openTime.setUTCHours(openHour, openMinute, 0, 0);

          const closeTime = new Date(callbackTime);
          const [closeHour, closeMinute] = schedule.closeTime.split(':');
          closeTime.setUTCHours(closeHour, closeMinute, 0, 0);

          // Adjust for day changes if close time is earlier than open time (spanning midnight)
          if (closeTime.getUTCHours() < openTime.getUTCHours()) {
              closeTime.setUTCDate(closeTime.getUTCDate() + 1);
          }

          // Convert openTime and closeTime to the user's timezone
          const openTimeInUserTz = new Date(openTime.toLocaleString('en-US', { timeZone: userTimezone }));
          const closeTimeInUserTz = new Date(closeTime.toLocaleString('en-US', { timeZone: userTimezone }));

          console.log('openTime: ', openTimeInUserTz);
          console.log('closeTime: ', closeTimeInUserTz);
          console.log('callbackTime: ', callbackTime);

          // Check if the callback time falls within the open and close times
          if (callbackTime >= openTimeInUserTz && callbackTime <= closeTimeInUserTz) {
              await this.setCallback();
          } else {
              alert('The selected callback time is outside the available schedule.');
          }
      } catch (error) {
          console.error('Error checking the schedule or setting the callback:', error);
      }
  },
    getCurrentDayName() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = new Date();
      return days[today.getDay()];
    },
    convertToUTC(callbackTime, timezone) {
      const timezoneMapping = {
          'EST': 'America/New_York',
          'EDT': 'America/New_York',
          'CST': 'America/Chicago',
          'CDT': 'America/Chicago',
          'MST': 'America/Denver',
          'MDT': 'America/Denver',
          'PST': 'America/Los_Angeles',
          'PDT': 'America/Los_Angeles',
          'AKST': 'America/Anchorage',
          'AKDT': 'America/Anchorage',
          'HST': 'Pacific/Honolulu',
          'HDT': 'Pacific/Honolulu' // Hawaii doesn't observe DST, but included for consistency
      };

      const fullTimezone = timezoneMapping[timezone];

      if (!fullTimezone) {
          throw new Error(`Invalid timezone: ${timezone}`);
      }

      // Use luxon to parse the date in the prospect's timezone and convert it to UTC
      const utcDateTime = DateTime.fromISO(callbackTime, { zone: fullTimezone }).toUTC();

      return utcDateTime.toISO();
  },
    async setCallback() {
      try {
          // Log the initial callback time for debugging
          console.log('setCallbackBeforeConvert: ', this.callbackTime);

          // Convert the callbackTime from the prospect's timezone to UTC
          const utcCallbackTime = this.convertToUTC(this.callbackTime, this.prospect.timezone);

          console.log('callbackDate: ', this.callbackTime);
          console.log('utcCallbackTime: ', utcCallbackTime);

          // Prepare the data to send to the server
          const callbackData = {
              ownerName: this.prospect.owner,
              companyName: this.prospect.company_name,
              callbackTime: utcCallbackTime, // Send the time in UTC format
              sales_rep_id: this.getCognitoSub(),
              leadId: this.prospect.leadId,
              timezone: this.prospect.timezone,
              userTime: new Date().toISOString() // Send the user time in the browser's timezone
          };

          // Make the POST request
          const response = await axios.post('https://123avatars.com/v2/setCallback', callbackData);

          alert('Callback set successfully');
          this.closeModal();
      } catch (error) {
          console.error('There was an error setting the callback:', error);
      }
  }

    }
  };
  </script>
  
  <style scoped>
  
  </style>