<template>
	<svg viewBox="0 0 10 19" class="navcaretSVG">
	   <path d="M9.69557 10.4435L2.40537 18.5428C2.22912 18.739 2.02083 18.8282 1.76447 18.8282C1.50811 18.8282 1.29982 18.739 1.12357 18.5428L0.290407 17.6151C0.11416 17.4189 0.0180252 17.187 0.0020028 16.9015C-0.0140196 16.6161 0.0660925 16.3842 0.242339 16.188L6.05848 9.71209L0.258362 3.25407C0.082115 3.05783 0.0180252 2.80807 0.0180252 2.54047C0.0180252 2.27287 0.130182 2.02312 0.306429 1.82688L1.12357 0.899207C1.29982 0.702969 1.50811 0.61377 1.76447 0.61377C2.02083 0.61377 2.22912 0.702969 2.40537 0.899207L9.69557 9.01633C9.88784 9.21257 10 9.44449 10 9.72993C10 10.0154 9.90387 10.2473 9.69557 10.4435Z" />
	 </svg>
</template>

<script>
export default {
   name: 'navcaretSVG',
}
</script>

<style scoped>
.navcaretSVG {

}
</style>