<template>
	<svg viewBox="0 0 55 19">
	  <g clip-path="url(#a)">
		<path d="M24.0862 9.74236c0 2.58964-2.0007 4.49794-4.4559 4.49794-2.4553 0-4.456-1.9083-4.456-4.49794 0-2.58968 2.0007-4.49798 4.456-4.49798 2.4552 0 4.4559 1.89005 4.4559 4.49798Zm-1.9506 0c0-1.6183-1.1595-2.72556-2.5053-2.72556s-2.5054 1.10726-2.5054 2.72556c0 1.61834 1.1596 2.72554 2.5054 2.72554 1.3458 0 2.5053-1.1255 2.5053-2.72554Z" fill="#EA4335"/>
		<path d="M33.699 9.74236c0 2.58964-2.0007 4.49794-4.4559 4.49794-2.4553 0-4.456-1.9083-4.456-4.49794 0-2.58968 2.0007-4.49798 4.456-4.49798 2.4552 0 4.4559 1.89005 4.4559 4.49798Zm-1.9506 0c0-1.6183-1.1596-2.72556-2.5053-2.72556-1.3458 0-2.5054 1.10726-2.5054 2.72556 0 1.61834 1.1596 2.72554 2.5054 2.72554 1.3457 0 2.5053-1.1255 2.5053-2.72554Z" fill="#FBBC05"/>
		<path d="M42.9111 5.51613v8.07527c0 3.3218-1.9346 4.6784-4.2216 4.6784-2.1529 0-3.4486-1.458-3.9373-2.6505l1.6983-.7158c.3024.7321 1.0434 1.596 2.237 1.596 1.4639 0 2.3711-.9146 2.3711-2.6364v-.6469h-.0681c-.4365.5455-1.2777 1.0221-2.3391 1.0221-2.2209 0-4.2556-1.959-4.2556-4.47972 0-2.52073 2.0347-4.5142 4.2556-4.5142 1.0594 0 1.9006.47657 2.3391 1.00586h.0681v-.73208h1.8525v-.00203Zm-1.7143 4.24245c0-1.58382-1.0434-2.74178-2.3711-2.74178-1.3278 0-2.4733 1.15796-2.4733 2.74178 0 1.58382 1.1275 2.70932 2.4733 2.70932s2.3711-1.1417 2.3711-2.70932Z" fill="#4285F4"/>
		<path d="M45.9653.78296V13.9646h-1.9026V.78296h1.9026Z" fill="#34A853"/>
		<path d="m53.3792 11.2228 1.514 1.0221c-.4886.7321-1.6662 1.9935-3.7009 1.9935-2.5234 0-4.4079-1.9752-4.4079-4.498 0-2.67485 1.9006-4.49797 4.1896-4.49797 2.2891 0 3.4326 1.8576 3.8011 2.86143l.2023.51104-5.938 2.4903c.4546.9024 1.1616 1.3628 2.1529 1.3628.9913 0 1.6822-.4948 2.1869-1.2452ZM48.719 9.60453l3.9693-1.66899c-.2183-.56174-.8752-.95314-1.6482-.95314-.9913 0-2.3712.88621-2.3211 2.62213Z" fill="#EA4335"/>
		<path d="M7.97262 8.57229v-1.9083h6.35048c.0621.33259.0941.72601.0941 1.15187 0 1.43173-.3865 3.20214-1.6322 4.46354-1.2116 1.2776-2.7596 1.959-4.81038 1.959-3.80106 0-6.99733-3.1352-6.99733-6.98428C.9773 3.40509 4.17356.2699 7.97462.2699c2.10278 0 3.60078.83551 4.72628 1.92451l-1.3297 1.34656c-.8071-.76657-1.90058-1.36278-3.39858-1.36278-2.7757 0-4.94659 2.26521-4.94659 5.07593 0 2.81068 2.17089 5.07598 4.94659 5.07598 1.8004 0 2.82578-.7321 3.48268-1.3973.5327-.5394.8831-1.31004 1.0213-2.36254H7.97262v.00203Z" fill="#4285F4"/>
	  </g>
	</svg>
</template>

<script>
export default {
   name: 'googleSVG',
}
</script>

<style scoped>
.googleSVG {

}
</style>