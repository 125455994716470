<template>
	<svg viewBox="0 0 44 20" class="voicemailSVG">
	   <path d="M10 0C4.50084 0 0 4.50084 0 10C0 15.4992 4.50084 20 10 20H34C39.4992 20 44 15.4992 44 10C44 4.50084 39.4992 0 34 0C28.5008 0 24 4.50084 24 10C24 12.265 24.7801 14.3184 26.0625 16H17.9375C19.2199 14.3184 20 12.265 20 10C20 4.50084 15.4992 0 10 0ZM10 4C13.3374 4 16 6.6626 16 10C16 13.3374 13.3374 16 10 16C6.6626 16 4 13.3374 4 10C4 6.6626 6.6626 4 10 4ZM34 4C37.3374 4 40 6.6626 40 10C40 13.3374 37.3374 16 34 16C30.6626 16 28 13.3374 28 10C28 6.6626 30.6626 4 34 4Z" />
	 </svg>
</template>

<script>
export default {
   name: 'voicemailSVG',
}
</script>

<style scoped>
.voicemailSVG {

}
</style>