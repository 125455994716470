<template>
	<svg viewBox="0 0 19 16" class="logoutSVG">
	  <path d="M7.24 14.864c0 .324-.145.477-.45.477H3.62c-.998 0-1.866-.375-2.558-1.125A3.876 3.876 0 0 1 0 11.506v-7.67C0 2.78.354 1.86 1.062 1.127 1.77.395 2.606.003 3.62.003h3.17c.305 0 .45.153.45.477v1.602c0 .324-.145.478-.45.478H3.62c-.322 0-.612.119-.853.374a1.247 1.247 0 0 0-.354.904v7.67c0 .34.113.647.354.903.241.255.515.375.853.375h3.17c.305 0 .45.153.45.477v1.602Zm11.503-6.51-6.34 6.714c-.273.29-.61.358-.98.205s-.564-.46-.564-.886v-3.835H5.727a.836.836 0 0 1-.643-.273.941.941 0 0 1-.258-.682V5.763c0-.273.08-.495.258-.682a.836.836 0 0 1 .643-.273h5.132V.973c0-.426.194-.715.563-.886.37-.17.708-.085.982.205l6.339 6.715a.941.941 0 0 1 .257.682.94.94 0 0 1-.257.681v-.017Z"/>
	</svg>
</template>

<script>
export default {
   name: 'logoutSVG',
}
</script>

<style scoped>
.logoutSVG {

}
</style>