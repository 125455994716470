<template>
	<svg viewBox="0 0 28 25" class="eraseSVG">
		 <g clip-path="url(#clip0_102_793)">
		   <path d="M27.23 13.5L19.46 21.4286H27.3467C27.79 21.4286 28 21.6429 28 22.0952V24.3333C28 24.7857 27.79 25 27.3467 25H7.88667C7.16333 25 6.53333 24.7381 6.02 24.2143L0.77 18.8571C0.256667 18.3333 0 17.7143 0 16.9524C0 16.1905 0.256667 15.5714 0.77 15.0476L14.77 0.785714C15.2833 0.261905 15.89 0 16.6367 0C17.3833 0 17.99 0.261905 18.5033 0.785714L27.2533 9.71429C27.7667 10.2381 28.0233 10.8571 28.0233 11.619C28.0233 12.381 27.7667 13 27.2533 13.5238L27.23 13.5ZM10.6633 10L3.87333 16.9762L8.26 21.4524H14.49L18.2 17.7143L10.6633 10.0238V10Z" />
		 </g>
	   </svg>
</template>

<script>
export default {
   name: 'eraseSVG',
}
</script>

<style scoped>
.eraseSVG {

}
</style>