import { render, staticRenderFns } from "./paginrightdoubleSVG.vue?vue&type=template&id=187dd358&scoped=true"
import script from "./paginrightdoubleSVG.vue?vue&type=script&lang=js"
export * from "./paginrightdoubleSVG.vue?vue&type=script&lang=js"
import style0 from "./paginrightdoubleSVG.vue?vue&type=style&index=0&id=187dd358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187dd358",
  null
  
)

export default component.exports