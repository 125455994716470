<!-- UI-Box.vue -->
<template>
	<div class="box" :class="classbox" > 
		<div class="header" :class="classheader">
			<div class="title">
				<slot name="title"></slot>
			</div>
			<slot name="button" :onClick="onClick"></slot>
		</div>
		<div class="content" :class="classcontent">
			<!-- default content -->
			<slot></slot>
			<!-- two column content -->
			<div v-if="slots.leftcol" class="half-left">
				<slot name="leftcol"></slot>
			</div>
			<div v-if="slots.rightcol" class="half-right">
				<slot name="rightcol"></slot>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useSlots } from 'vue'
	const slots = useSlots()
	
	defineProps(['classbox', 'classheader', 'classcontent', 'title'])
	
	
	function onClick() {
	  this.isActive = !this.isActive;
	}
	
</script>