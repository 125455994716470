<template>
<svg viewBox="0 0 53 53" class="saveSVG">
	<path d="M50.86 11.48c1.09 1.09 1.64 2.42 1.64 3.98v31.41c0 1.56-.55 2.89-1.64 3.98-1.09 1.09-2.42 1.64-3.98 1.64H5.62c-1.56 0-2.89-.55-3.98-1.64C.55 49.76 0 48.43 0 46.87V5.62c0-1.56.55-2.89 1.64-3.98C2.73.55 4.06 0 5.62 0h31.41c1.56 0 2.89.55 3.98 1.64l9.84 9.84h.01ZM37.5 9.26c0-.31-.16-.62-.47-.94l-.35-.35c-.31-.31-.63-.47-.94-.47H8.91c-.94 0-1.41.47-1.41 1.41V21.1c0 .94.47 1.41 1.41 1.41H36.1c.94 0 1.41-.47 1.41-1.41V9.26h-.01ZM20.92 42.83c1.44 1.45 3.22 2.17 5.33 2.17s3.89-.72 5.33-2.17 2.17-3.22 2.17-5.33-.72-3.89-2.17-5.33S28.36 30 26.25 30s-3.89.72-5.33 2.17-2.17 3.22-2.17 5.33.72 3.89 2.17 5.33Z"/>
</svg>
</template>

<script>
export default {
   name: 'saveSVG',
}
</script>

<style scoped>
.saveSVG {

}
</style>