<template>
	<svg viewBox="0 0 19 18" class="paginleftdoubleSVG">
	  <path d="M0.334868 8.28599L8.35409 0.282076C8.54797 0.0881483 8.77709 2.465e-08 9.05908 0C9.34108 -2.465e-08 9.5702 0.0881483 9.76407 0.282076L10.6806 1.19882C10.8744 1.39275 10.9802 1.62194 10.9978 1.90401C11.0154 2.18609 10.9273 2.41528 10.7334 2.60921L4.33567 9.00882L10.7158 15.3908C10.9097 15.5847 10.9802 15.8315 10.9802 16.096C10.9802 16.3604 10.8568 16.6072 10.6629 16.8012L9.76407 17.7179C9.5702 17.9119 9.34108 18 9.05908 18C8.77709 18 8.54797 17.9119 8.3541 17.7179L0.334869 9.69638C0.123373 9.50245 2.466e-08 9.27326 0 8.99119C-2.466e-08 8.70911 0.105748 8.47992 0.334868 8.28599Z" />
	  <path d="M8.33487 8.28599L16.3541 0.282076C16.548 0.0881483 16.7771 2.465e-08 17.0591 0C17.3411 -2.465e-08 17.5702 0.0881483 17.7641 0.282076L18.6806 1.19882C18.8744 1.39275 18.9802 1.62194 18.9978 1.90401C19.0154 2.18609 18.9273 2.41528 18.7334 2.60921L12.3357 9.00882L18.7158 15.3908C18.9097 15.5847 18.9802 15.8315 18.9802 16.096C18.9802 16.3604 18.8568 16.6072 18.6629 16.8012L17.7641 17.7179C17.5702 17.9119 17.3411 18 17.0591 18C16.7771 18 16.548 17.9119 16.3541 17.7179L8.33487 9.69638C8.12337 9.50245 8 9.27326 8 8.99119C8 8.70911 8.10575 8.47992 8.33487 8.28599Z" />
	</svg>
</template>

<script>
export default {
   name: 'paginleftdoubleSVG',
}
</script>

<style scoped>
.paginleftdoubleSVG {

}
</style>