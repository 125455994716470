<template>
	<svg viewBox="0 0 19 18" class="paginrightdoubleSVG">
	  <path d="M18.6651 9.71401L10.6459 17.7179C10.452 17.9119 10.2229 18 9.94092 18C9.65892 18 9.4298 17.9119 9.23593 17.7179L8.3194 16.8012C8.1256 16.6072 8.0198 16.3781 8.0022 16.096C7.9846 15.8139 8.0727 15.5847 8.2666 15.3908L14.6643 8.99118L8.2842 2.6092C8.0903 2.4153 8.0198 2.1685 8.0198 1.904C8.0198 1.6396 8.1432 1.3928 8.3371 1.1988L9.23593 0.282098C9.4298 0.0880979 9.65892 -2.72397e-06 9.94092 -2.69932e-06C10.2229 -2.67467e-06 10.452 0.088098 10.6459 0.282098L18.6651 8.30362C18.8766 8.49755 19 8.72674 19 9.00881C19 9.29089 18.8943 9.52008 18.6651 9.71401Z" />
	  <path d="M10.6651 9.71401L2.64591 17.7179C2.45203 17.9119 2.22291 18 1.94092 18C1.65892 18 1.4298 17.9119 1.23593 17.7179L0.319401 16.8012C0.125601 16.6072 0.0198012 16.3781 0.00220125 16.096C-0.0153987 15.8139 0.0727008 15.5847 0.266601 15.3908L6.66433 8.99118L0.284202 2.6092C0.090302 2.4153 0.0198025 2.1685 0.0198025 1.904C0.0198026 1.6396 0.143202 1.3928 0.337102 1.1988L1.23593 0.282098C1.4298 0.0880979 1.65892 -2.72397e-06 1.94092 -2.69932e-06C2.22291 -2.67467e-06 2.45203 0.088098 2.6459 0.282098L10.6651 8.30362C10.8766 8.49755 11 8.72674 11 9.00881C11 9.29089 10.8943 9.52008 10.6651 9.71401Z" />
	</svg>
</template>

<script>
export default {
   name: 'paginrightdoubleSVG',
}
</script>

<style scoped>
.paginrightdoubleSVG {

}
</style>