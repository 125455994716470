<template>
  <div style="width:100%; margin-top:5%; margin-left:1%;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <div class="container-fluid" style="overflow-x: scroll;">
                  <h2>Scoreboard Report</h2>
                  <audio id="telnyx-audio" autoplay></audio>
                  <table>
                    <thead>
                      <tr>
                        <th rowspan="2">Listen</th>
                        <th rowspan="2">Messages</th>
                        <th rowspan="2">Rep</th>
                        <th rowspan="2">Manager</th>
                        <th rowspan="2">Logged in Time</th>
                        <th rowspan="2">Time Since Last Call</th>
                        <th rowspan="2">Current Call</th>
                        <th rowspan="2">Shelf Life</th>
                        <!-- <th rowspan="2">Sales</th>
                        <th rowspan="2">Calls / Sale</th>
                        <th rowspan="2">Income</th>
                        <th rowspan="2">Income / Hour Talk Time</th> -->
                        <th colspan="6">Monday</th>
                        <th colspan="6">Tuesday</th>
                        <th colspan="6">Wednesday</th>
                        <th colspan="6">Thursday</th>
                        <th colspan="6">Friday</th>
                        <th colspan="6">Saturday</th>
                        <th colspan="6">Sunday</th>
                        <th colspan="6">This Week</th>
                        <th colspan="6">Last Week</th>
                        <th colspan="6">This Month</th>
                        <th colspan="6">Last Month</th>
                      </tr>
                      <tr>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Start Time (PST)</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                        <th>Talk Time</th>
                        <th>2 Min Calls / Total Calls</th>
                        <th>Barges</th>
                        <th>Completed TO's / TO's Requested</th>
                        <th>Spiff Income</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="rep in salesReps" :key="rep.id" :class="getClass(rep)">
                        <td>
                          <!-- <button @click="listen(rep.id)" v-if="!rep.isListening">Listen</button> -->
                          <i class="fa-solid fa-ear-listen" v-if="rep.isListening"></i>
                          <i 
                            class="fa-solid fa-exclamation" 
                            v-if="rep.toRequested == true" 
                            style="color: red; font-size: 2rem;">
                          </i>
                        </td>
                        <td>
                          <div class="scoreboard-messanger-icon" align-self="center">
                            <span class="badge" v-if="rep.numUnread > 0">{{rep.numUnread}}</span>
                            <a class="align-items-center" @click="openMessages(rep.id, rep.name)" >
                            <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 0C9.40363 0 0 6.92535 0 15.475C0 20.968 3.888 25.7867 9.74655 28.5332C7.58764 32.9139 4.51104 36 4.51104 36C10.3406 35.3158 13.6829 32.7367 15.552 30.4184C17.2907 30.7629 19.1164 30.95 21 30.95C32.5964 30.95 42 24.0246 42 15.475C42 6.92535 32.6012 0 21 0Z" fill="white"/>
                            </svg>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div>
                            <a @click="openRepDashboard(rep.id)" class="link-highlight">{{ rep.name }}</a>
                          </div>
                          <i class="fas fa-phone-slash" style="margin-left: 8px;" @click="openMissedTOs(rep.id)"></i>
                        </td>
                        <td>
                          {{  rep.manager }}
                        </td>
                        <!-- <td>{{ formatTime(rep.loggedInTime) }}</td> -->
                        <td>-</td>
                        <td>{{ formatTime(timeSinceLastCall(rep.lastCallEndTime))  }}</td>
                        <td>{{ formatTime(rep.currentCallTime) }}</td>
                        <td>{{ rep.shelfLife }}</td>
                        <!-- <td>{{ rep.sales }}</td>
                        <td>{{ rep.callsPerSale }}</td>
                        <td>{{ rep.income }}</td>
                        <td>{{ rep.incomePerHour }}</td> -->
                        <td class="shaded">{{ getStartTime(rep.data, 'Monday') }}</td>
                        <td class="shaded">{{ getTalkTime(rep.data, 'Monday') }}</td>
                        <td class="shaded">{{ getCalls(rep.data, 'Monday') }}</td>
                        <td class="shaded">{{ getBarges(rep.data, 'Monday') }}</td>
                        <td class="shaded">{{ getTOs(rep.data, 'Monday') }}</td>
                        <td class="shaded">{{ getSpiffIncome(rep.data, 'Monday') }}</td>
                        <td>{{ getStartTime(rep.data, 'Tuesday') }}</td>
                        <td>{{ getTalkTime(rep.data, 'Tuesday') }}</td>
                        <td>{{ getCalls(rep.data, 'Tuesday') }}</td>
                        <td>{{ getBarges(rep.data, 'Tuesday') }}</td>
                        <td>{{ getTOs(rep.data, 'Tuesday') }}</td>
                        <td>{{ getSpiffIncome(rep.data, 'Tuesday') }}</td>
                        <td class="shaded">{{ getStartTime(rep.data, 'Wednesday') }}</td>
                        <td class="shaded">{{ getTalkTime(rep.data, 'Wednesday') }}</td>
                        <td class="shaded">{{ getCalls(rep.data, 'Wednesday') }}</td>
                        <td class="shaded">{{ getBarges(rep.data, 'Wednesday') }}</td>
                        <td class="shaded">{{ getTOs(rep.data, 'Wednesday') }}</td>
                        <td class="shaded">{{ getSpiffIncome(rep.data, 'Wednesday') }}</td>
                        <td>{{ getStartTime(rep.data, 'Thursday') }}</td>
                        <td>{{ getTalkTime(rep.data, 'Thursday') }}</td>
                        <td>{{ getCalls(rep.data, 'Thursday') }}</td>
                        <td>{{ getBarges(rep.data, 'Thursday') }}</td>
                        <td>{{ getTOs(rep.data, 'Thursday') }}</td>
                        <td>{{ getSpiffIncome(rep.data, 'Thursday') }}</td>
                        <td class="shaded">{{ getStartTime(rep.data, 'Friday') }}</td>
                        <td class="shaded">{{ getTalkTime(rep.data, 'Friday') }}</td>
                        <td class="shaded">{{ getCalls(rep.data, 'Friday') }}</td>
                        <td class="shaded">{{ getBarges(rep.data, 'Friday') }}</td>
                        <td class="shaded">{{ getTOs(rep.data, 'Friday') }}</td>
                        <td class="shaded">{{ getSpiffIncome(rep.data, 'Friday') }}</td>
                        <td>{{ getStartTime(rep.data, 'Saturday') }}</td>
                        <td>{{ getTalkTime(rep.data, 'Saturday') }}</td>
                        <td>{{ getCalls(rep.data, 'Saturday') }}</td>
                        <td>{{ getBarges(rep.data, 'Saturday') }}</td>
                        <td>{{ getTOs(rep.data, 'Saturday') }}</td>
                        <td>{{ getSpiffIncome(rep.data, 'Saturday') }}</td>
                        <td class="shaded">{{ getStartTime(rep.data, 'Sunday') }}</td>
                        <td class="shaded">{{ getTalkTime(rep.data, 'Sunday') }}</td>
                        <td class="shaded">{{ getCalls(rep.data, 'Sunday') }}</td>
                        <td class="shaded">{{ getBarges(rep.data, 'Sunday') }}</td>
                        <td class="shaded">{{ getTOs(rep.data, 'Sunday') }}</td>
                        <td class="shaded">{{ getSpiffIncome(rep.data, 'Sunday') }}</td>
                        <td>{{ getTotalTalkTime(rep.data, 'thisWeek') }}</td>
                        <td>{{ getTotalCalls(rep.data, 'thisWeek') }}</td>
                        <td>{{ getTotalBarges(rep.data, 'thisWeek') }}</td>
                        <td>{{ getTotalTOs(rep.data, 'thisWeek') }}</td>
                        <td>{{ getTotalSpiffIncome(rep.data, 'thisWeek') }}</td>
                        <td>{{ getTotalTalkTime(rep.data, 'lastWeek') }}</td>
                        <td>{{ getTotalCalls(rep.data, 'lastWeek') }}</td>
                        <td>{{ getTotalBarges(rep.data, 'lastWeek') }}</td>
                        <td>{{ getTotalTOs(rep.data, 'lastWeek') }}</td>
                        <td>{{ getTotalSpiffIncome(rep.data, 'lastWeek') }}</td>
                        <td>{{ getTotalTalkTime(rep.data, 'thisMonth') }}</td>
                        <td>{{ getTotalCalls(rep.data, 'thisMonth') }}</td>
                        <td>{{ getTotalBarges(rep.data, 'thisMonth') }}</td>
                        <td>{{ getTotalTOs(rep.data, 'thisMonth') }}</td>
                        <td>{{ getTotalSpiffIncome(rep.data, 'thisMonth') }}</td>
                        <td>{{ getTotalTalkTime(rep.data, 'lastMonth') }}</td>
                        <td>{{ getTotalCalls(rep.data, 'lastMonth') }}</td>
                        <td>{{ getTotalBarges(rep.data, 'lastMonth') }}</td>
                        <td>{{ getTotalTOs(rep.data, 'lastMonth') }}</td>
                        <td>{{ getTotalSpiffIncome(rep.data, 'lastMonth') }}</td>
                      </tr>
                      <tr class="total-row">
                        <td colspan="8">Totals</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Monday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Monday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Monday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Monday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Monday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Tuesday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Tuesday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Tuesday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Tuesday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Tuesday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Wednesday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Wednesday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Wednesday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Wednesday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Wednesday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Thursday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Thursday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Thursday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Thursday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Thursday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Friday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Friday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Friday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Friday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Friday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Saturday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Saturday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Saturday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Saturday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Saturday', 'spiffs') }}</td>
                        <td>-</td>
                        <td>{{ getColumnTotal('Sunday', 'talkTime') }}</td>
                        <td>{{ getColumnTotal('Sunday', 'calls') }}</td>
                        <td>{{ getColumnTotal('Sunday', 'barges') }}</td>
                        <td>{{ getColumnTotal('Sunday', 'tos') }}</td>
                        <td>{{ getColumnTotal('Sunday', 'spiffs') }}</td>
                        <td>{{ getPeriodTotal('thisWeek', 'talkTime') }}</td>
                        <td>{{ getPeriodTotal('thisWeek', 'calls') }}</td>
                        <td>{{ getPeriodTotal('thisWeek', 'barges') }}</td>
                        <td>{{ getPeriodTotal('thisWeek', 'tos') }}</td>
                        <td>{{ getPeriodTotal('thisWeek', 'spiffs') }}</td>
                        <td>{{ getPeriodTotal('lastWeek', 'talkTime') }}</td>
                        <td>{{ getPeriodTotal('lastWeek', 'calls') }}</td>
                        <td>{{ getPeriodTotal('lastWeek', 'barges') }}</td>
                        <td>{{ getPeriodTotal('lastWeek', 'tos') }}</td>
                        <td>{{ getPeriodTotal('lastWeek', 'spiffs') }}</td>
                        <td>{{ getPeriodTotal('thisMonth', 'talkTime') }}</td>
                        <td>{{ getPeriodTotal('thisMonth', 'calls') }}</td>
                        <td>{{ getPeriodTotal('thisMonth', 'barges') }}</td>
                        <td>{{ getPeriodTotal('thisMonth', 'tos') }}</td>
                        <td>{{ getPeriodTotal('thisMonth', 'spiffs') }}</td>
                        <td>{{ getPeriodTotal('lastMonth', 'talkTime') }}</td>
                        <td>{{ getPeriodTotal('lastMonth', 'calls') }}</td>
                        <td>{{ getPeriodTotal('lastMonth', 'barges') }}</td>
                        <td>{{ getPeriodTotal('lastMonth', 'tos') }}</td>
                        <td>{{ getPeriodTotal('lastMonth', 'spiffs') }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="modal" v-if="isVisible">
                    <div class="modal-content">
                      
                      <Chat :type="'MANAGER'" :salesRepId="currentSalesRepId" :managerId="managerId" :headerTitle="`Chat with ${currentSalesRepName}`" :phoneNumber="listenNumber" :repId="repId" :callId="callId" @close-modal="closeModal"/>
                    </div>
                  </div>
                  <div class="modal" v-if="isListen">
                    <Listen :phoneNumber="listenNumber" :repId="repId" @modal-closed="handleModalClosed"></Listen>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { TelnyxRTC } from '@telnyx/webrtc';
import Chat from './Chat.vue';
import Listen from "./Listen.vue";
import SocketService from './socketService.js';
import AuthService from '../../_services/AuthService.js';

export default {
  components: { Multiselect, Datepicker, 'Chat': Chat, 'Listen': Listen },
  data() {
    return {
      salesReps: [],
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      audio: null,
      outboundHuman: false,
      callDestination: '',
      includeVideo: false,
      enableDebugging: false,
      authentication: 'credential',
      username: 'cmharrisiii70663',
      password: 'cPeNxcb1',
      callerIdName: '',
      callerIdNumber: '',
      clientState: '',
      telnyxRTC: null,
      call: null,
      dialpad: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['*', '0', '#']
      ],
      managerId: '',
      currentSalesRepId: '',
      currentSalesRepName: '',
      isVisible: false,
      listenNumber: '+19495461850',
      isListen: false,
      repId: '',
      callId: '',
      role: '',
      now: new Date()
    };
  },
  computed() {
    
  },
  async mounted() {
    await this.setManagerId();
    this.configureSockets();
    //this.fetchSalesReps();
    
    //this.startTimeSinceLastCallTimer();
    // if(this.role == 'Admins' && this.$route.path == 'scoreboard') {
    //   this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
    //   setInterval(() => {
    //     this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
    //   }, 60000);
    // } else {
    //   this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
    //   setInterval(() => {
    //     this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
    //   }, 60000);
    // }
    this.fetchSalesReps('https://123avatars.com/v2/scoreboard');
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 1000);
    this.initLocalTimers();  
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    clearInterval(this.interval);
  },
  methods: {
    openRepDashboard(salesRepId) {
      let host = '';
      if(this.role == 'Admins') {
        host = 'https://beta.123avatars.com';
      }
      if(this.role == 'Managers') {
        host = 'https://myleadfarm.co';
      }
      window.open(host + '/my-sales?id=' + salesRepId, '_blank');
    },
    openMissedTOs(salesRepId) {
      console.log('asdf', this.$route.path);
      if(this.$route.path == '/manager-dashboard') {
        console.log('asdf', this.$route.path);
        this.$emit('missed-tos', salesRepId);
      }
      
      if(this.$route.path == '/scoreboard') {
        this.$router.push({ path: '/call-report', query: { salesRepId: salesRepId, toCallsFilter: 'Missed' } });
      }
    },
    timeSinceLastCall(endLastCall) {
      const end = endLastCall || new Date();
      const timeDifference = this.now - new Date(end); // Difference in milliseconds
      let timeDiff = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
      return timeDiff;
    },
    initLocalTimers() {
      this.salesReps.forEach((rep) => {
        //this.startLocalTimerforRep(rep);
        this.startLocalTimerForRep(rep);
      });
    },
    configureSockets() {
      SocketService.connect();
      SocketService.register(this.managerId);
      SocketService.on('new_message', this.handleNewMessage);
      SocketService.on('request-to', this.notifyTORequest);
      SocketService.on('call-update', this.callUpdate);
      SocketService.on('barge-update', this.bargeUpdate);
      SocketService.on('analysis-update', this.analysisUpdate);
      SocketService.on('user-status', this.userStatus);
      SocketService.on('call-status', this.callStatus);
      SocketService.on('heartbeat', this.handleHeartbeat);
    },
    async setManagerId() {
      let role = await AuthService.getUserRole();
      this.role = role;
      if(role == 'Admins' && this.$route.path == '/scoreboard') {
        //target += '?id=' + this.$route.query.id;
        this.managerId = '123';
      }
      
      if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
        this.managerId = this.$route.query.id;
      }
      if(role == 'Managers') {
        this.managerId = await AuthService.getCognitoSub();
      }
      console.log('managerId: ', this.managerId);
    },
    handleHeartbeat(heartbeatData) {
      //console.log('heartbeatData: ', heartbeatData);
      const rep = this.salesReps.find(rep => rep.id === heartbeatData.salesRepId);
      if (rep) {
        this.updateRepFromHeartbeat(rep, heartbeatData);
      }
    },
    updateRepFromHeartbeat(rep, heartbeatData) {
      // Update the rep's data from the latest heartbeat
      if(rep.isLoggedIn) {
        if(heartbeatData.loggedInTime != 0) {
          let currDiff = rep.loggedInTime - rep.lastLoggedInTime;
          if(currDiff != heartbeatData.loggedInTime) {
            rep.loggedInTime = rep.lastLoggedInTime + heartbeatData.loggedInTime;
          }
        }
      }
      
      //rep.talkTime = heartbeatData.talkTime;
      //rep.currentCallTime = heartbeatData.currentCallTime;

      console.log('rep: ', rep);

      // Start local incrementing until the next heartbeat arrives
      this.startLocalTimerForRep(rep);
    },
    startLocalTimerForRep(rep) {
//      console.log('startTimer: ', rep); 
      if (rep.localTimer) {
        clearInterval(rep.localTimer); // Clear existing timer if running
        rep.localTimer = null;
      }

      // Start a new local timer to increment time every second
      rep.localTimer = setInterval(() => {
        //if() // call status if user ACTIVE increment
      //  console.log('tick');
        if(rep.isLoggedIn) {
          rep.loggedInTime += 1;
        }
        if(rep.isActive == true) {
          rep.currentCallTime += 1;
          rep.timeSinceLastCall = 0;
        } else {
          if(rep.timeSinceLastCall != -1) {
            rep.timeSinceLastCall += 1;
          }
        }
     //   console.log(`Local time incremented for rep ${rep.id}`);
      }, 1000);
    },
    handleCallEndEvent(callEndData) {
      const rep = this.salesReps.find(rep => rep.id === callEndData.salesRepId);
      if (rep) {
        rep.currentCallTime = 0;
        rep.lastCallEndTime = new Date(callEndData.callEndTime);
        rep.toRequested = false;
        this.startLocalTimerForRep(rep);
        //this.startTimeSinceLastCallTimer(rep, lastCallEndTime);
      }
    },
    // startTimeSinceLastCallTimer(rep, lastCallEndTime) {
    //   console.log('startTimer: ', rep);
    //   // Clear any existing timer
    //   if (rep.timeSinceLastCallTimer) {
    //     clearInterval(rep.timeSinceLastCallTimer);
    //   }

    //   const now = new Date();
    //   let timeSinceLastCall = Math.floor((now - lastCallEndTime) / 1000); // Calculate the initial value

    //   // Start a new timer to increment every second
    //   rep.timeSinceLastCallTimer = setInterval(() => {
    //     rep.timeSinceLastCall += 1;
    //     //rep.timeSinceLastCall = timeSinceLastCall;
    //     console.log(`Updated timeSinceLastCall for ${rep.id}: ${rep.timeSinceLastCall} seconds`);
    //   }, 1000);
    // },
    notifyTORequest(data) {
      try {
        console.log("salesReps: ", this.salesReps);
        console.log("data: ", data);
        this.salesReps.forEach((rep) => {
          if(rep.id == data.sales_rep_id) {
            console.log("rep: ", rep);
            rep.toRequested = true;
            this.callId = data.callId;

          // Find the latest day for the rep
          const latestDay = Object.keys(rep.data).sort().pop();
          const latestDayData = rep.data[latestDay];

          // If latest day data is found, update the requestedTOs
          if (latestDayData) {
            // Update requestedTOs
            latestDayData.requestedTOs = (latestDayData.requestedTOs || 0) + 1;
            console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
          } else {
            console.error(`No data found for the latest day for rep ${rep.id}`);
          }
          }
        }); 
      } catch (err) { 
        console.error('error notifying manager of TO request: ', err);
      }
    },
    callUpdate(data) {
      // Iterate through the salesReps list to find the matching salesRepId
      this.salesReps.forEach((rep) => {
        if (rep.id === data.salesRepId) {
          console.log("Updating rep: ", rep);

          // Find the latest day for the rep
          const latestDay = Object.keys(rep.data).sort().pop();
          const latestDayData = rep.data[latestDay];

          // If latest day data is found, update the talkTime and humanCalls
          if (latestDayData) {
            // Update talkTime
            latestDayData.talkTime = (latestDayData.talkTime || 0) + data.talkTime;
            // Update humanCalls (mapped from twoMinCalls)
            latestDayData.humanCalls = (latestDayData.humanCalls || 0) + data.twoMinCalls;

            console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
          } else {
            console.error(`No data found for the latest day for rep ${rep.id}`);
          }
        }
      });
    },
    bargeUpdate(data) {
      // Iterate through the salesReps list to find the matching salesRepId
      console.log('bargeUpdate: ', data);
      this.salesReps.forEach((rep) => {
        if (rep.id === data.salesRepId) {
          // console.log("Updating barge count for rep: ", rep);

          // // Find the latest day for the rep
          // const latestDay = Object.keys(rep.data).sort().pop();
          // const latestDayData = rep.data[latestDay];

          // // If latest day data is found, increment the barges count
          // if (latestDayData) {
          //   latestDayData.barges = (latestDayData.barges || 0) + 1;
          //   console.log(`Barge count updated for ${rep.name} on ${latestDay}. New barges count: ${latestDayData.barges}`);
          // } else {
          //   console.error(`No data found for the latest day for rep: ${rep.id}`);
          // }
        }
      });
    },
    analysisUpdate(data) {
      // Iterate through the salesReps list to find the matching salesRepId
      this.salesReps.forEach((rep) => {
        if (rep.id === data.salesRepId) {
          console.log("Updating completedTOs for rep: ", rep);

          // Find the latest day for the rep
          const latestDay = Object.keys(rep.data).sort().pop();
          const latestDayData = rep.data[latestDay];

          // If latest day data is found, increment the completedTOs count
          if (latestDayData) {
            latestDayData.completedTOs = (latestDayData.completedTOs || 0) + 1;
            console.log(`Completed TOs updated for ${rep.name} on ${latestDay}. New completedTOs count: ${latestDayData.completedTOs}`);
          } else {
            console.error(`No data found for the latest day for rep: ${rep.id}`);
          }
        }
      });
    },
    userStatus(userStatusData) {
      const { salesRepId, status, duration, connectedDuration } = userStatusData;
      console.log('data: ', userStatusData);
      // Find the sales rep in the list
      const rep = this.salesReps.find(rep => rep.id === salesRepId);
      if (!rep) {
        console.error(`Sales rep with id ${salesRepId} not found.`);
        return;
      }

      switch (status) {
        case 'ACTIVE':
          console.log(`Sales rep ${salesRepId} is now active.`);
          rep.isLoggedIn = true;
          rep.isPaused = false;
          rep.isActive = true;
          rep.lastLoggedInTime = rep.loggedInTime;
          //rep.loggedInTime = duration || 0;  // Reset or set logged in time
          //rep.talkTime = connectedDuration || 0;  // Set initial talk time if provided
          break;

        case 'OFFLINE':
          console.log(`Sales rep ${salesRepId} is now offline.`);
          rep.isLoggedIn = false;
          rep.isPaused = false;
          rep.isActive = false;
          rep.lastLoggedInTime = rep.loggedInTime;
          //rep.loggedInTime = duration || rep.loggedInTime;  // Finalize logged time
          rep.talkTime = connectedDuration || rep.talkTime;  // Finalize talk time
          // You can stop any timers here, if applicable
          break;

        case 'PAUSED':
          console.log(`Sales rep ${salesRepId} has paused.`);
          rep.isPaused = true;
          rep.isActive = false;
          //rep.loggedInTime = duration || rep.loggedInTime;  // Update logged time
          rep.talkTime = connectedDuration || rep.talkTime;  // Update talk time
          // Pause any ongoing call tracking or timers if applicable
          break;

        default:
          console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
      }
    },
    callStatus(callStatusData) {
      const { salesRepId, status, callEndTime } = callStatusData;
      
      const rep = this.salesReps.find(rep => rep.id === salesRepId);
      if (!rep) {
        console.error(`Sales rep with id ${salesRepId} not found.`);
        return;
      }

      switch (status) {
        case 'DIALING':
          console.log(`Sales rep ${salesRepId} is dialing.`);
          rep.isDialing = true;
          rep.isActive = false;
          rep.isPaused = false;
          break;

        case 'ACTIVE':
          console.log(`Sales rep ${salesRepId} is on an active call.`);
          rep.isDialing = false;
          rep.isActive = true;
          rep.isPaused = false;
          break;

        case 'PAUSED':
          console.log(`Sales rep ${salesRepId} has paused the call.`);
          rep.isDialing = false;
          rep.isActive = false;
          rep.isPaused = true;
          break;

        case 'INACTIVE':
          console.log(`Sales rep ${salesRepId} has ended the call.`);
          rep.isDialing = false;
          rep.isActive = false;
          rep.isPaused = false;
          this.handleCallEndEvent(callStatusData);  // Call handleCallEnd to process the call end
          break;

        default:
          console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
      }
    },
    handleNewMessage(message) {
      console.log('newMessage: ', message);
      if(message.sender == message.sales_rep_id) {
        this.salesReps.forEach((rep) => {
          if(rep.id == message.sales_rep_id) {
            rep.numUnread = rep.numUnread + 1;
          }
        });
      }
    },
    getClass(rep) {
      return {
        'connected': rep.currentCallTime > 0 && !rep.isDialing,
        'two-min': rep.currentCallTime > 120 && !rep.isDialing,
        'five-min': rep.currentCallTime > 300 && !rep.isDialing,
        'dialing': rep.isDialing
      };
    },
    loadId() {
      this.managerId = this.$route.query.id;
    },
    connect() {
      console.log("this.username: ", this.username);
      console.log("username: ", this.sipUsername);
      if(localStorage.getItem('userId') != null && localStorage.getItem('userId') != '') {
        console.log("config: ", {
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
          debug: this.enableDebugging,
          logLeveL: 'debug'
        });
        this.telnyxRTC = new TelnyxRTC({
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
          debug: this.enableDebugging,
          logLeveL: 'debug'
        });

        this.telnyxRTC.on('telnyx.socket.open', () => {
          console.log('Socket opened');
        });

        this.telnyxRTC.on('telnyx.socket.error', (error) => {
          console.error('Socket error', error);
        });

        this.telnyxRTC.on('telnyx.socket.close', () => {
          console.log('Socket closed');
        });

        this.telnyxRTC.on('telnyx.ready', () => {
          console.log('Telnyx ready');
        });

        this.telnyxRTC.on('telnyx.error', (error) => {
          console.error('Telnyx error', error);
        });

        this.telnyxRTC.on('telnyx.call.incoming', (call) => {
          this.call = call;
          console.log('Incoming call', call);
        });

        this.telnyxRTC.on('telnyx.call.answered', (call) => {
          console.log('Call answered', call);
        });

        this.telnyxRTC.on('telnyx.call.hangup', (call) => {
          console.log('Call hung up', call);
        });

        this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
          console.log("call: ", call);
        });

        this.telnyxRTC.on('telnyx.notification', (notification) => {
          console.log("notification: ", notification);
          this.call = notification.call;
          if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
            this.call.answer();
            
            // .then(() => {
            //   // Mute the call after answering
            //   this.call.muteAudio();
            // });
          }
          if (notification.type === 'callUpdate' && this.call.state === 'active') {
            this.attachStreamToAudioElement(this.call.remoteStream);
          }
        });

        // Handle call state changes
        this.telnyxRTC.on('telnyx.callStateChange', (call) => {
          console.log("stateChange: ", call);
          if (call.state === 'active') {
            attachStreamToAudioElement(call.remoteStream);
          }
        });


        this.telnyxRTC.on('telnyx.call.started', () => {
          console.log('Call started');
        });

        this.telnyxRTC.on('telnyx.call.ended', () => {
          console.log('Call ended');
        });

        this.telnyxRTC.on('telnyx.call.error', (error) => {
          console.error('Call error:', error);
        });

        this.telnyxRTC.on('telnyx.ice.candidate', (candidate) => {
          console.log('Call ICE candidate:', candidate);
        });

        this.telnyxRTC.on('telnyx.ice.stateChange', (state) => {
          console.log('Call ICE state change:', state);
        });

        this.telnyxRTC.connect();
      }
    },
    attachStreamToAudioElement(stream) {
        const audioElement = document.getElementById('telnyx-audio');
        audioElement.srcObject = stream;
      },
    navigateToLink(id) {
      this.connect();
      axios.get('https://123avatars.com/v2/barge-call?id=' + id)
        .then(response => {
          console.log("succes");
        })
        .catch(error => {
          console.error("Error barging call.", error);
        });
    },
    async fetchSalesReps(target) {
      let role = await AuthService.getUserRole();
      console.log('role: ', role);
      console.log('path: ', this.$route.path);
      if(role == 'Admins' && this.$route.path == '/scoreboard') {
        //target += '?id=' + this.$route.query.id;
      }
      
      if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
      target += '?id=' + this.$route.query.id;
      }
      if(role == 'Managers') {
        target += '?id=' + await AuthService.getCognitoSub();
      }
      axios.get(target)
        .then(response => {
          this.salesReps = response.data;// .filter(rep => rep.id == 'b9a9a9fe-f051-70f4-1ea8-6f786eb62621');
          // this.salesReps.forEach((rep) => {
          //   //this.startLocalTimerforRep(rep);
          //   this.startLocalTimerForRep(rep);
          //   //this.startTimeSinceLastCallTimer(rep, rep.timeSinceLastCall);
          // });
          console.log('salesReps: ', this.salesReps);
        })
        .catch(error => {
          console.error('Error fetching sales reps data:', error);
        });
    },
    getDayKey(day) {
      const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

      const dayIndex = this.daysOfWeek.indexOf(day);  // Find the index of the day
      
      // Create a new Date object to avoid modifying the original `today` object
      const adjustedDate = new Date(today);
      
      // Adjust the date based on the current day of the week, treating Monday as day 1
      const currentDay = today.getDay();  // Get the current day (0 = Sunday, 1 = Monday, etc.)


      
      // If today is Sunday (0), we treat it as the last day of the week (7) to align with Monday start
      const offset = currentDay === 0 ? 7 : currentDay;
      
      // console.log('dayIdx: ', dayIndex);
      // console.log('currentDay: ', currentDay);
      // console.log('offset: ', offset);

      // Calculate the correct date, considering the day index
      adjustedDate.setDate(today.getDate() - offset + dayIndex + 1);

      // Return the day key formatted in Pacific Time
      return adjustedDate.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        timeZone: 'America/Los_Angeles'
      });
    },
    getStartTime(data, day) {
      const key = this.getDayKey(day);
      const startTime = data[key]?.startTime;
      const timezone = data[key]?.timezone;
      if (!startTime || !timezone || timezone === 'undefined') {
        return '-';
      }

      // Initialize the date in UTC
      const date = new Date(`${startTime}Z`); // Ensure it's treated as UTC
      if (isNaN(date)) {
        return '-';
      }

      const options = {
        timeZone: 'America/Los_Angeles',  // Convert to Pacific Time
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };

      return new Intl.DateTimeFormat('en-US', options).format(date);
    },
    formatTime(seconds) {
      if (seconds < 0) {
        return '-';
      }

      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let formattedTime = '';

      if (hours > 0) {
        formattedTime += `${hours}h `;
      }
      if (minutes > 0 || hours > 0) {
        formattedTime += `${minutes}m `;
      }
      formattedTime += `${secs}sec`;

      return formattedTime.trim();
    },
    getTalkTime(data, day) {
      const key = this.getDayKey(day);
      const talkTime = data[key]?.talkTime;
      return talkTime ? this.formatTime(talkTime) : '-';
    },
    getCalls(data, day) {
      const key = this.getDayKey(day);
      //console.log(' key: ', key);
      let callsCount = (data[key]?.humanCalls || '-') + '/' + (data[key]?.calls || '-');
      let humanPercent = 0;
      if(data[key]?.humanCalls > 0) {
        humanPercent = parseFloat((data[key]?.humanCalls / data[key]?.calls) * 100).toFixed(1);
        callsCount += '\n' + (humanPercent) + '%';
      }
      return callsCount;
    },
    getBarges(data, day) {
      const key = this.getDayKey(day);
      const barges = data[key]?.barges;
      return barges ? barges : '-';
    },
    getTOs(data, day) {
      const key = this.getDayKey(day);
      let tosCount = (data[key]?.completedTOs || '-') + '/' + (data[key]?.requestedTOs || '-');
      let toPercent = 0;
      if(data[key]?.completedTOs > 0 && data[key]?.requestedTOs > 0) {
        toPercent = parseFloat((data[key]?.completedTOs / data[key]?.requestedTOs) * 100).toFixed(1);
        tosCount += '\n' + toPercent + '%';
      }
      return tosCount;
    },
    getSpiffIncome(data, day) {
      const key = this.getDayKey(day);
      let spiffIncome = this.formatCurrency(data[key]?.spiffIncome || 0);
      return spiffIncome;
    },
    formatCurrency(amount) {
      return `₱${amount.toLocaleString()}`;
    },
    getTotalTalkTime(data, period) {
      const dates = this.getPeriodDates(period);
      let total = 0;
      dates.forEach(date => {
        if (data[date] && data[date].talkTime) {
          total += parseFloat(data[date].talkTime);
        }
      });
      return total ? this.formatTime(total) : '-';
    },
    getTotalCalls(data, period) {
      const dates = this.getPeriodDates(period);
      let total = 0;
      let humanTotal = 0;
      dates.forEach(date => {
        if (data[date] && data[date].calls) {
          total += data[date].calls;
          humanTotal += parseInt(data[date].humanCalls);
        }
      });
      
      // console.log('humanCalls: ', humanTotal);

      let callsCount = (humanTotal || '-') + '/' + (total || '-');
      if (humanTotal > 0 && total > 0) {
        let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
        callsCount += '\n' + humanPercent + '%';
      }
      
      return callsCount;
    },
    getTotalBarges(data, period) {
      const dates = this.getPeriodDates(period);
      let total = 0;
      dates.forEach(date => {
        if (data[date] && data[date].barges) {
          total += parseFloat(data[date].barges);
        }
      });
      return total ? total : '-';
    },
    getTotalTOs(data, period) {
      const dates = this.getPeriodDates(period);
      let totalRequestedTOs = 0;
      let totalCompletedTOs = 0;

      dates.forEach(date => {
        if (data[date] && data[date].requestedTOs) {
          totalRequestedTOs += parseInt(data[date].requestedTOs);
          totalCompletedTOs += parseInt(data[date].completedTOs);
        }
      });

      let tosCount = (totalCompletedTOs || '-') + '/' + (totalRequestedTOs || '-');
      if (totalCompletedTOs > 0 && totalRequestedTOs > 0) {
        let toPercent = parseFloat((totalCompletedTOs / totalRequestedTOs) * 100).toFixed(1);
        tosCount += '\n' + toPercent + '%';
      }

      return tosCount;
    },
    getTotalSpiffIncome(data, period) {
      const dates = this.getPeriodDates(period);
      let totalSpiffIncome = 0;

      dates.forEach(date => {
        if (data[date] && data[date].spiffIncome) {
          totalSpiffIncome += parseInt(data[date].spiffIncome);
        }
      });

      let spiffIncome = this.formatCurrency(totalSpiffIncome);
      return spiffIncome;
    },
    getPeriodDates(period) {
      const today = new Date();
      let dates = [];
      const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };

      if (period === 'thisWeek') {
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Start of this week
        for (let i = 0; i < 7; i++) {
          dates.push(startOfWeek.toLocaleDateString('en-US', options));
          startOfWeek.setDate(startOfWeek.getDate() + 1);
        }
      } else if (period === 'lastWeek') {
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() - 6)); // Start of last week
        for (let i = 0; i < 7; i++) {
          dates.push(startOfWeek.toLocaleDateString('en-US', options));
          startOfWeek.setDate(startOfWeek.getDate() + 1);
        }
      } else if (period === 'thisMonth') {
        const start = new Date(today.getFullYear(), today.getMonth(), 1);
        const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          dates.push(new Date(d).toLocaleDateString('en-US', options));
        }
      } else if (period === 'lastMonth') {
        const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const end = new Date(today.getFullYear(), today.getMonth(), 0);
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          dates.push(new Date(d).toLocaleDateString('en-US', options));
        }
      }
      
      return dates;
    },
    getColumnTotal(day, key) {
      let total = 0;
      let humanTotal = 0;
      let completedTOsTotal = 0;
      let requestedTOsTotal = 0;
      let spiffIncomeTotal = 0;

      this.salesReps.forEach(rep => {
        const dayKey = this.getDayKey(day);
        if (rep.data[dayKey]) {
          if (key === 'calls') {
            total += parseInt(rep.data[dayKey].calls) || 0;
            humanTotal += parseInt(rep.data[dayKey].humanCalls) || 0;
          } else if (key === 'barges') {
            total += parseInt(rep.data[dayKey].barges) || 0;
          } else if (key === 'tos') {  // Handling TOs
            completedTOsTotal += parseInt(rep.data[dayKey].completedTOs) || 0;
            requestedTOsTotal += parseInt(rep.data[dayKey].requestedTOs) || 0;
          } else if(key === 'spiffs') {
            spiffIncomeTotal += parseInt(rep.data[dayKey].spiffIncome) || 0;
          } else {
            total += parseInt(rep.data[dayKey][key]) || 0;
          }
        }
      });

      if (key === 'calls') {
        let callsCount = (humanTotal || '-') + '/' + (total || '-');
        if (humanTotal > 0 && total > 0) {
          let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
          callsCount += '\n' + humanPercent + '%';
        }
        return callsCount;
      } else if (key === 'tos') {  // Returning TOs total
        let tosCount = (completedTOsTotal || '-') + '/' + (requestedTOsTotal || '-');
        if (completedTOsTotal > 0 && requestedTOsTotal > 0) {
          let toPercent = parseFloat((completedTOsTotal / requestedTOsTotal) * 100).toFixed(1);
          tosCount += '\n' + toPercent + '%';
        }
        return tosCount;
      } else if (key === 'talkTime') {
        return this.formatTime(total);
      } else if(key === 'spiffs') {
        return this.formatCurrency(spiffIncomeTotal);
      } else {
        return total;
      }
    },
    getPeriodTotal(period, key) {
      let total = 0;
      let humanTotal = 0;
      let completedTOsTotal = 0;
      let requestedTOsTotal = 0;
      let spiffIncomeTotal = 0;

      this.salesReps.forEach(rep => {
        const dates = this.getPeriodDates(period);
        dates.forEach(date => {
          if (rep.data[date]) {
            if (key === 'calls') {
              total += parseInt(rep.data[date].calls) || 0;
              humanTotal += parseInt(rep.data[date].humanCalls) || 0;
            } else if (key === 'barges') {
              total += parseInt(rep.data[date].barges) || 0;
            } else if (key === 'tos') {  // Handling TOs
              completedTOsTotal += parseInt(rep.data[date].completedTOs) || 0;
              requestedTOsTotal += parseInt(rep.data[date].requestedTOs) || 0;
            } else if(key === 'spiffs') {
              spiffIncomeTotal += parseInt(rep.data[date].spiffIncome) || 0;
            } else {
              total += parseInt(rep.data[date][key]) || 0;
            }
          }
        });
      });

      if (key === 'calls') {
        let callsCount = (humanTotal || '-') + '/' + (total || '-');
        if (humanTotal > 0 && total > 0) {
          let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
          callsCount += '\n' + humanPercent + '%';
        }
        return callsCount;
      } else if (key === 'tos') {  // Returning TOs total
        let tosCount = (completedTOsTotal || '-') + '/' + (requestedTOsTotal || '-');
        if (completedTOsTotal > 0 && requestedTOsTotal > 0) {
          let toPercent = parseFloat((completedTOsTotal / requestedTOsTotal) * 100).toFixed(1);
          tosCount += '\n' + toPercent + '%';
        }
        return tosCount;
      } else if (key === 'talkTime') {
        return this.formatTime(total);
      } else if(key === 'spiffs') {
        return this.formatCurrency(spiffIncomeTotal);
      } else {
        return total;
      }
    },
    listen(repId) {  
      //this.listenNumber = repNumber;
      console.log("listenNumber: ", this.listenNumber);
      this.repId = repId;
      this.isListen = true;
    },
    handleModalClosed() {
      this.isListen = false;
      // Additional state updates can go here
      console.log('Modal closed');
    },
    openMessages(salesRepId, salesRepName) {
      this.salesReps.forEach((rep) => {
        if(rep.id == salesRepId) {
          rep.toRequested = false;
        }
      });
      this.currentSalesRepId = salesRepId;
      this.repId = salesRepId;
      this.currentSalesRepName = salesRepName;
      //this.managerId = // this.$route.query.id;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.connected {
  background-color: yellow;
}
.two-min {
  background-color: green;
}
.five-min {
  background-color: orange;
}
.dialing {
  background-color: lightblue; /* New color for dialing */
}
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #00C1C1;
  color: white;
}

td:nth-child(1) {
  background-color: #00C1C1;
}

.total-row {
  background-color: #00C1C1;
  font-weight: bold;
}

.active {
  background-color: #d4edda;
}

.inactive {
  background-color: #f8d7da;
}

.shaded {
  background-color: lightgray;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
.link-highlight {
    color: blue; /* Change to your desired link color */
    text-decoration: underline; /* Underline to mimic link style */
    cursor: pointer; /* Show pointer cursor on hover */
}

.link-highlight:hover {
    color: darkblue; /* Change color on hover */
}


</style>