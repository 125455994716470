<template>
	<svg viewBox="0 0 12 13" class="accordiancaretSVG">
	   <path d="M11.1738 0L11.1738 13L0.173828 6.5L11.1738 0Z" fill="#46A57C" />
	 </svg>
</template>

<script>
export default {
   name: 'accordiancaretSVG',
}
</script>

<style scoped>
.accordiancaretSVG {

}
</style>