<template>
	<div id="notifications">
		
		<div v-for="notif in notificiations" :key="notif.id" class="notification">
			<div class="header" :class="{ alert: notif.alert }">
				<div class="text">{{ notif.title }}</div>
				<button class="btn">
					<CloseSVG />
				 </button>
			</div>
			<div class="body">
				<div v-if="notif.number" class="big-num">{{ notif.number }}</div>
				<div class="text">{{ notif.text }}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import CloseSVG from './SVG-icons/closeSVG.vue';
	
	export default {
		components: { CloseSVG },
		data() {
			return {
				// notifications list
				notificiations: [
					{ title: 'Team TOs', number: '#84', text: 'Just came in', alert: false },
					{ title: 'Upcoming Call ',  number: '2:23', alert: true },
					{ title: 'Hello', text: 'Generic Notification', alert: false }
				]
			}
		}
	}
</script>